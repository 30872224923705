import React from "react";
import {
  // VideoList,
  // FirstPersonDisplay,
  isMobileDevice,
  selectLocalPeer,
  useHMSStore,
  Text,
} from "@100mslive/hms-video-react";
import { ChatView } from "./chatView";
import { getBlurClass, date, isToday } from "../../common/utils";
import { VideoList } from './VideoList/VideoList';
import { ROLES } from '../../common/roles';
import { WaitingScreenDisplay } from './WaitingScreenDisplay/WaitingScreenDisplay';

const MAX_TILES_FOR_MOBILE = 4;

export const GridLeftView = ({
  isChatOpen,
  isParticipantListOpen,
  totalPeers,
  toggleChat,
}) => {
  return isChatOpen && (
    <div
      // className={`h-1/2 w-2/10 absolute z-40 bottom-20 left-0 top-20 ${getBlurClass(
      className={`md:w-2/5 lg:w-1/5 lg:w absolute z-40 bottom-20 left-5 right-5 top-5 md:top-20 ${getBlurClass(
        isParticipantListOpen,
        totalPeers
      )}`}
    >
      <ChatView toggleChat={toggleChat} />
    </div>
  );
};

// The center of the screen shows bigger tiles
export const GridCenterView = ({
  event,
  peers,
  maxTileCount,
  allowRemoteMute,
  isChatOpen,
  // toggleChat,
  // isParticipantListOpen,
  hideSidePane,
  // totalPeers,
  videoTileProps,
}) => {
  const localPeer = useHMSStore(selectLocalPeer);
  const iamHost = localPeer.roleName === ROLES.HOST;

  const start = event.start ? date(event.start).subtract(event.timeDiff) : null;
  const today = isToday(start);
  const startDate = start && start.format("MMM D"); // Jan 1
  const startTime = start && start.format("LT"); // 1:00 PM

  const hostJoined = iamHost || peers?.some(p => p.roleName === ROLES.HOST) || false;

  const waitingHostToStart = !event.started && !hostJoined;
  const waitingGuestsToJoin = iamHost && !peers?.length;

  return (
    <div
      className={`h-full ${
        // hideSidePane ? "w-full" : "w-full md:w-4/5"
        hideSidePane && !isChatOpen ? "w-full" : hideSidePane || !isChatOpen ? "w-full md:w-4/5" : "w-full md:w-4/5 transform md:translate-x-40"
      }`}
    >
      {waitingHostToStart || waitingGuestsToJoin ? (
        <WaitingScreenDisplay
          event={event}
          username={localPeer.name}
          classes={{ rootBg: "h-full" }}
          message={(
            waitingHostToStart ? (
              <Text tag="h2" variant="heading" size="md">
                <div>Waiting for the host to start the meeting</div>

                {start && (
                  <div>{today ? `scheduled at ${startTime}` : `scheduled at ${startTime} on ${startDate}`}</div>
                )}
              </Text>
            ) : waitingGuestsToJoin ? (
              <Text tag="h2" variant="heading" size="md">
                <div>Sit back and relax till the others join.</div>
                <div className="mt-4">{today ? `Scheduled start: ${startTime}` : `Scheduled start: ${startDate}, ${startTime}`}</div>
              </Text>
            ) : null
          )}
        />
      ) : peers && peers.length > 0 ? (
        <VideoList
          peers={peers}
          classes={{
            videoTileContainer: "rounded-lg",
          }}
          maxColCount={2}
          maxTileCount={isMobileDevice() ? MAX_TILES_FOR_MOBILE : maxTileCount}
          compact={true}
          allowRemoteMute={allowRemoteMute}
          videoTileProps={videoTileProps}
        />
      ) : (
        <WaitingScreenDisplay
          event={event}
          username={localPeer.name}
          classes={{ rootBg: "h-full" }}
          message={(
            <Text tag="h2" variant="heading" size="md">
              <div>Sit back and relax till the others join.</div>
              <div className="mt-4">{today ? `Scheduled start: ${startTime}` : `Scheduled start: ${startDate}, ${startTime}`}</div>
            </Text>
          )}
        />
      )}
    </div>
  );
};

// Side pane shows smaller tiles
export const GridSidePaneView = ({
  peers,
  // isChatOpen,
  // toggleChat,
  // isParticipantListOpen,
  // totalPeers,
}) => {
  const isMobile = isMobileDevice();
  const rowCount = isMobile ? 1 : undefined;

  return (
    <div className="flex flex-col w-full h-1/4 md:w-1/5 md:h-full pl-3 pr-3 md:pl-0 md:pr-0">
      <div className="flex flex-1 items-end w-full">
        {peers && peers.length > 0 && (
          <VideoList
            peers={peers}
            classes={{
              root: "",
              videoTileContainer: `rounded-lg ${isMobile ? "p-0 mr-2" : ""}`,
            }}
            maxColCount={2}
            maxRowCount={rowCount}
            compact={true}
          />
        )}
      </div>
    </div>
  );
};
