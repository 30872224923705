const DEFAULT_RETRY_POLICY = {
  retries: 4,
  delay: attempt => (attempt * attempt) * 100 // 0ms, 100ms, 400ms, 900ms
};

export const getBackendEndpoint = () => {
  let baseUrl = '';

  const baseDomain = window.location.hostname;
  if (baseDomain === "qa2.100ms.live" || process.env.REACT_APP_ENV === "stg") {
    baseUrl = process.env.REACT_APP_STG_BACKEND_API || "https://qa-in.100ms.live/hmsapi/";
  } else if (baseDomain === "prod2.100ms.live" || process.env.REACT_APP_ENV === "prod") {
    baseUrl = process.env.REACT_APP_PROD_BACKEND_API || "https://prod-in.100ms.live/hmsapi/";
  } else {
    baseUrl = process.env.REACT_APP_BACKEND_API || "https://prod-in.100ms.live/hmsapi/";
  }

  return baseUrl;
};

export const extractUrlCode = () => {
  const path = window.location.pathname;
  let roomCode = null;
  if (path.startsWith("/preview/") || path.startsWith("/meeting/") || path.startsWith('/leave/')) {
    roomCode = path.split('/').slice(2, 3).join('/').trim();
    if (roomCode.trim() === "") roomCode = null;
  }
  return roomCode;
};

/**
 * @param {RequestInfo} url
 * @param {RequestInit} options
 * @returns {Promise<Response>}
 */
export const fetchWithRetry = async (url, options, retryPolicy) => {
  const maxRetries = Number.isFinite(retryPolicy?.retries) ? clamp(retryPolicy.retries, 0, Infinity) : DEFAULT_RETRY_POLICY.retries;

  let error = Error("something went wrong");
  for (let i = 0; i <= maxRetries; i++) {
    try {
      const response = await fetch(url, options);

      if (options?.json) {
        const body = await response.json();
        return body;
      }

      return response;
    } catch (err) {
      error = err;

      const delayFn = retryPolicy?.delay || DEFAULT_RETRY_POLICY.delay;
      const nextAttemptTime = clamp(delayFn(i), 0, 10000) || 0; // 0..10 sec max
      await delay(nextAttemptTime);
    }
  }
  console.error("Fetch failed after max retries", { url, options }, error);
  throw error;
};

export const clamp = (value, min, max) => {
  return value > max ? max : value < min ? min : Number(value);
};

export const delay = async (timeout) => {
  return new Promise(resolve => setTimeout(resolve, timeout));
};
