import {
  useHMSActions,
  useHMSStore,
  selectLocalPeer,
  selectPeerMetadata,
} from "@100mslive/hms-video-react";

export const useMetadata = () => {
  const hmsActions = useHMSActions();
  const peer = useHMSStore(selectLocalPeer);
  const metadata = useHMSStore(selectPeerMetadata(peer?.id));

  const isHandRaised = metadata?.isHandRaised || false;
  const setIsHandRaised = async isHandRaised => change({ isHandRaised });

  const avatarUrl = metadata?.avatarUrl || '';
  const setAvatarUrl = async avatarUrl => change({ avatarUrl });

  const change = async obj => {
    setMetadata({ ...metadata, ...obj });
  };

  const setMetadata = async obj => {
    try {
      const body = obj || {};
      await hmsActions.changeMetadata(body);
    } catch (error) {
      console.error("failed to update metadata", error);
    }
  };

  return {
    isHandRaised,
    setIsHandRaised,
    avatarUrl: avatarUrl,
    setAvatarUrl: setAvatarUrl
  };
};
