import { useState, useCallback, useEffect } from "react";
import {
  useHMSStore,
  ControlBar,
  Button,
  ShareScreenIcon,
  ChatIcon,
  HandIcon,
  ChatUnreadIcon,
  VerticalDivider,
  MessageModal,
  RecordingDot,
  useHMSActions,
  selectIsLocalScreenShared,
  selectUnreadHMSMessagesCount,
  isMobileDevice,
  selectIsAllowedToPublish,
  selectIsConnectedToRoom,
  selectLocalPeer,
  selectRecordingState,
  selectRTMPState,
} from "@100mslive/hms-video-react";
import { useMetadata } from './hooks/useMetadata';
import { MoreSettings } from "./components/MoreSettings";
import { AudioVideoToggle } from "./components/AudioVideoToggle";
import { LeaveRoom } from "./components/LeaveRoom";
import { MeetingEndingCountDown } from './components/MeetingEndingCountDown/MeetingEndingCountDown';
import { ROLES } from '../common/roles';
import { joinQueryParams } from '../common/utils';
import { BEAM_BOT } from '../common/constants';
import urlJoin from 'url-join';
import { hmsToast } from './components/Notifications/hms-toast';
import { CircleStopIcon } from './components/Icons';

const beamBotMeetingUrl = joinQueryParams(window.location.href, `${BEAM_BOT}=true`);

export const ConferenceFooter = ({ event, role, isChatOpen, toggleChat }) => {
  const isScreenShared = useHMSStore(selectIsLocalScreenShared);
  const countUnreadMessages = useHMSStore(selectUnreadHMSMessagesCount);
  const hmsActions = useHMSActions();
  const isConnected = useHMSStore(selectIsConnectedToRoom);
  const localPeer = useHMSStore(selectLocalPeer);
  const recording = useHMSStore(selectRecordingState);
  const rtmp = useHMSStore(selectRTMPState);

  const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);
  const { isHandRaised, setIsHandRaised } = useMetadata();

  const amIHost = localPeer?.roleName.toLowerCase() === ROLES.HOST;

  const initialModalProps = {
    show: false,
    title: "",
    body: "",
  };
  const [errorModal, setErrorModal] = useState(initialModalProps);
  const [initRecording, setInitRecording] = useState(null);
  const [initRecordingTimer, setInitRecordingTimer] = useState(null);

  const toggleScreenShare = useCallback(
    async (enable, audioOnly = false) => {
      try {
        await hmsActions.setScreenShareEnabled(enable, audioOnly);
      } catch (error) {
        if (
          error.description &&
          error.description.includes("denied by system")
        ) {
          setErrorModal({
            show: true,
            title: "Screen share permission denied by OS",
            body: "Please update your OS settings to permit screen share.",
          });
        } else if (error.message && error.message.includes("share audio")) {
          // when share audio not selected with audioOnly screenshare
          setErrorModal({
            show: true,
            title: "Screenshare error",
            body: error.message,
          });
        } else if (
          error.message &&
          error.message === "Cannot share multiple screens"
        ) {
          // when share audio not selected with audioOnly screenshare
          setErrorModal({
            show: true,
            title: "Screenshare error",
            body: error.message,
          });
        }
      }
    },
    [hmsActions]
  );

  const startStopRTMPRecording = async (action, retry = false) => {
    try {
      clearTimeout(initRecordingTimer);

      setInitRecording(action);
      setInitRecordingTimer(setTimeout(() => setInitRecording(null), 30000));

      if (action === "start" || action === "start_norec") {
        const meetingURL = beamBotMeetingUrl;
        const record     = action === "start_norec" ? false : true;
        const rtmpURLs   = [];
        const youtube    = event.livestreaming.youtube || {};
    
        if (youtube.streamUrl) {
          const url = youtube.streamKey ? urlJoin(youtube.streamUrl, '/' + youtube.streamKey) : youtube.streamUrl;
          rtmpURLs.push(url);
        }

        await hmsActions.startRTMPOrRecording({
          meetingURL,
          rtmpURLs: rtmpURLs.length ? rtmpURLs : undefined,
          record,
        });
      } else if (action === "stop") {
        await hmsActions.stopRTMPAndRecording();

        if (event.livestreaming.prepared && !event.livestreaming.finished) {
          await startStopRTMPRecording("start_norec");
        }
      } else {
        throw new Error(`invalid action: ${action}`);
      }
    } catch (error) {
      if (error?.description === "RTMP stream already running" && !retry) {
        await hmsActions.stopRTMPAndRecording();
        await startStopRTMPRecording(action, true);
      }
      else {
        console.error("failed to start/stop rtmp/recording", error);
        hmsToast(error.message);
        clearTimeout(initRecordingTimer);
        setInitRecording(null);
        setInitRecordingTimer(null);
      }
    }
  };

  const leftComponents = [];

  leftComponents.push(
    <Button
      key="chat"
      iconOnly
      variant="no-fill"
      iconSize="md"
      shape="rectangle"
      onClick={toggleChat}
      active={isChatOpen}
    >
      {countUnreadMessages === 0 ? <ChatIcon /> : <ChatUnreadIcon />}
    </Button>
  );
  leftComponents.push(
    <Button
      key="raise-hand"
      iconOnly
      variant="no-fill"
      iconSize="md"
      shape="rectangle"
      onClick={() => setIsHandRaised(!isHandRaised)}
      active={isHandRaised}
    >
      <HandIcon />
    </Button>
  );

  useEffect(() => {
    const isRecording = recording?.browser?.running || recording?.server?.running;

    if (initRecording === "start" && isRecording) {
      setInitRecording(null);
    }

    if (initRecording === "start_norec" && isRecording) {
      setInitRecording(null);
    }

    if (initRecording === "stop" && !isRecording) {
      setInitRecording(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recording, recording.browser, recording.browser.running, rtmp, rtmp.running]);

  const isPublishing = isAllowedToPublish.video || isAllowedToPublish.audio;

  if (!isConnected) {
    return null;
  }

  const min = 60 * 1000;

  return (
    <>
      <ControlBar
        leftComponents={leftComponents}
        centerComponents={[
          <AudioVideoToggle key="audioVideoToggle" />,
          isAllowedToPublish.screen && !isMobileDevice() ? (
            <Button
              key="toggleScreenShare"
              iconOnly
              variant="no-fill"
              iconSize="md"
              shape="rectangle"
              classes={{ root: "mx-2" }}
              onClick={() => toggleScreenShare(!isScreenShared)}
            >
              <ShareScreenIcon />
            </Button>
          ) : null,
          isPublishing && (
            <span key="SettingsLeftSpace" className="mx-2 md:mx-3"></span>
          ),
          isPublishing && <VerticalDivider key="SettingsDivider" />,
          isPublishing && (
            <span key="SettingsRightSpace" className="mx-2 md:mx-3"></span>
          ),
          <MoreSettings key="MoreSettings" event={event} />,
          amIHost && event.options?.recording && !event.options?.custom?.livestream && (
            recording.browser.running ? (
              <Button
                key="stopRecording"
                iconOnly
                variant="no-fill"
                iconSize="md"
                shape="rectangle"
                classes={{ root: "mx-2" }}
                onClick={() => startStopRTMPRecording("stop")}
                disabled={initRecording}
              >
                <CircleStopIcon className={initRecording ? "fill-current text-gray-600" : "fill-current text-red-600"} />
              </Button>
            ) : (
              <Button
                key="startRecording"
                iconOnly
                variant="no-fill"
                iconSize="md"
                shape="rectangle"
                classes={{ root: "mx-2" }}
                onClick={() => startStopRTMPRecording("start")}
                disabled={initRecording}
              >
                {/* <CircleDotIcon className={initRecording ? "fill-current text-gray-600" : "fill-current text-red-600"} /> */}
                <RecordingDot className={initRecording ? "fill-current text-gray-600" : "fill-current text-red-600"} />
              </Button>
            )
          )
        ]}
        rightComponents={[
          role === ROLES.HOST && <MeetingEndingCountDown key="countDown" last={5 * min} event={event} />,
          <LeaveRoom key="leaveRoom" />
        ]}
      />
      <MessageModal
        {...errorModal}
        onClose={() => setErrorModal(initialModalProps)}
      />
    </>
  );
};
