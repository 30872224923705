import React, { useMemo } from 'react';
import { useHMSTheme } from '@100mslive/hms-video-react';
import { hmsUiClassParserGenerator } from '../../../common/utils';
import { RowLayout } from './RowLayout';
import './index.css';

const defaultClasses = {
  feedbackChoiceSection: 'mt-2',
  formLabel: 'text-sm text-gray-400 mb-2 block',
  feedbackChoices: 'flex-wrap',
};

export const FeedbackForm = ({
  classes,
  onChoiceChangeHandler,
  userComment,
  userCommentHandler,
  showChoice,
}) => {
  const { tw } = useHMSTheme();
  const feedbackOptions = [
    'I could not hear others',
    'I could not see others',
    'Others could not hear me',
    'Others could not see me',
    'Poor audio quality',
    'Poor video quality',
  ];

  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-feedback-form',
      }),
    [tw, classes],
  );
  return (
    <div className={styler('feedbackChoiceSection')}>
      <div
        className={styler('feedbackChoices')}
        style={{ display: showChoice ? 'flex' : 'none' }}
      >
        {feedbackOptions.map((choice) => {
          return (
            <RowLayout
              key={choice}
              onChoiceChangeHandler={onChoiceChangeHandler}
              choice={choice}
            />
          );
        })}
      </div>
      <div className="feedback">
        <div className="form">
          <label htmlFor="feedbackText" className={styler('formLabel')}>
            Additional Comments
          </label>
          <textarea
            value={userComment}
            onChange={userCommentHandler}
            name="feedbackText"
            autoFocus={true}
          ></textarea>
        </div>
      </div>
    </div>
  );
};
