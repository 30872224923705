import React, { useState, useEffect, useMemo } from "react";
import {
  useHMSActions,
  useHMSStore,
  selectLocalPeer,
  selectAvailableRoleNames,
  selectRolesMap,
} from "@100mslive/hms-video-react";
import { FeatureFlags } from "./FeatureFlags";
import {
  convertLoginInfoToJoinConfig,
  normalizeAppPolicyConfig,
  setUpLogRocket,
} from "./appContextUtils";
import { getBackendEndpoint } from "../services/tokenService";
import { UI_SETTINGS_KEY, USERNAME_KEY, ORIGIN, DEVICE, OS } from "../common/constants";
import { parseOS } from "../common/utils";
import { useSearchParam } from 'react-use';
import useNavigator from "react-browser-navigator";

const AppContext = React.createContext(null);

const initialLoginInfo = {
  token:    null,
  roomId:   "",
  role:     "",
  username: "",
  event:    {},
  timeDiff: 0, // ms
  profile:  {},
  // env: process.env.REACT_APP_ENV
  //   ? process.env.REACT_APP_ENV + "-in"
  //   : "prod-in",
  env: "prod-in",
  audioMuted: false,
  videoMuted: false,
  selectedVideoInput: "default",
  selectedAudioInput: "default",
  selectedAudioOutput: "default",
};

const defaultTokenEndpoint = getBackendEndpoint();
const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");

const defaultUiSettings = {
  maxTileCount: 9,
  subscribedNotifications: {
    PEER_JOINED: true,
    PEER_LEFT: true,
    NEW_MESSAGE: true,
    ERROR: true,
    METADATA_UPDATED: true
  },
};

const uiSettingsFromStorage = localStorage.getItem(UI_SETTINGS_KEY)
  ? JSON.parse(localStorage.getItem(UI_SETTINGS_KEY))
  : defaultUiSettings;

const AppContextProvider = ({
  roomId = "",
  tokenEndpoint = defaultTokenEndpoint,
  policyConfig = envPolicyConfig,
  children,
}) => {
  const hmsActions = useHMSActions();
  const localPeer = useHMSStore(selectLocalPeer);
  const roleNames = useHMSStore(selectAvailableRoleNames);
  const rolesMap = useHMSStore(selectRolesMap);
  const appPolicyConfig = useMemo(
    () => normalizeAppPolicyConfig(roleNames, rolesMap, policyConfig),
    [roleNames, policyConfig, rolesMap]
  );
  initialLoginInfo.roomId = roomId;

  const [state, setState] = useState({
    loginInfo: initialLoginInfo,
    maxTileCount: uiSettingsFromStorage.maxTileCount,
    localAppPolicyConfig: {},
    subscribedNotifications: uiSettingsFromStorage.subscribedNotifications,
  });

  const { userAgent } = useNavigator();

  const origin = useSearchParam(ORIGIN) || "";
  const device = useSearchParam(DEVICE) || "";
  const os = useSearchParam(OS) || "";

  useEffect(() => {
    localStorage.setItem(
      UI_SETTINGS_KEY,
      JSON.stringify({
        maxTileCount: state.maxTileCount,
        subscribedNotifications: state.subscribedNotifications,
      })
    );
  }, [state.maxTileCount, state.subscribedNotifications]);

  useEffect(() => {
    if (state.loginInfo.username) {
      localStorage.setItem(USERNAME_KEY, state.loginInfo.username);
    }
  }, [state.loginInfo.username]);

  useEffect(() => {
    if (origin) {
      localStorage.setItem(ORIGIN, origin);
    } else {
      localStorage.removeItem(ORIGIN);
    }
  }, [origin]);

  useEffect(() => {
    if (device) {
      localStorage.setItem(DEVICE, device);
    } else {
      localStorage.removeItem(DEVICE);
    }
  }, [device]);

  useEffect(() => {
    if (os) {
      localStorage.setItem(OS, os);
      return;
    }

    const parsed = parseOS(userAgent);

    if (parsed) {
      localStorage.setItem(OS, parsed);
    } else {
      localStorage.removeItem(OS);
    }
  }, [userAgent, os]);

  useEffect(() => {
    function resetHeight() {
      // reset the body height to that of the inner browser
      document.body.style.height = `${window.innerHeight}px`;
    }
    // reset the height whenever the window's resized
    window.addEventListener("resize", resetHeight);
    // called to initially set the height.
    resetHeight();
    return () => {
      window.removeEventListener("resize", resetHeight);
    };
  }, []);

  useEffect(() => {
    if (!state.loginInfo.token) return;
    hmsActions.join(convertLoginInfoToJoinConfig(state.loginInfo));
    // eslint-disable-next-line
  }, [state.loginInfo.token]); // to avoid calling join again, call it only when token is changed

  useEffect(() => {
    localPeer && setUpLogRocket(state.loginInfo, localPeer);
    // eslint-disable-next-line
  }, [localPeer?.id]);

  useEffect(() => {
    localPeer && deepSetAppPolicyConfig(appPolicyConfig[localPeer.roleName]);
  }, [localPeer, localPeer?.roleName, appPolicyConfig]);

  // deep set with clone so react re renders on any change
  const deepSetLoginInfo = loginInfo => {
    const newState = {
      ...state,
      loginInfo: { ...state.loginInfo, ...loginInfo },
    };
    setState(newState);
    console.log(newState); // note: component won't reflect changes at time of this log
  };

  const deepSetMaxTiles = maxTiles =>
    setState(prevState => ({ ...prevState, maxTileCount: maxTiles }));

  const deepSetAppPolicyConfig = config =>
    setState(prevState => ({ ...prevState, localAppPolicyConfig: config }));

  const deepSetSubscribedNotifications = notification => {
    setState(prevState => ({
      ...prevState,
      subscribedNotifications: {
        ...prevState.subscribedNotifications,
        [notification.type]: notification.isSubscribed,
      },
    }));
  };

  return (
    <AppContext.Provider
      value={{
        setLoginInfo: deepSetLoginInfo,
        setMaxTileCount: deepSetMaxTiles,
        setSubscribedNotifications: deepSetSubscribedNotifications,
        loginInfo: state.loginInfo,
        maxTileCount: state.maxTileCount,
        subscribedNotifications: state.subscribedNotifications,
        appPolicyConfig: state.localAppPolicyConfig,
        tokenEndpoint,
      }}
    >
      {children}
      <FeatureFlags />
    </AppContext.Provider>
  );
};

export { AppContext, AppContextProvider };
