import React, { useMemo } from 'react';
import { useHMSTheme } from '@100mslive/hms-video-react';
import { hmsUiClassParserGenerator } from '../../../common/utils';

const defaultClasses = {
  checkBoxLabel: 'text-sm space-x-3 flex items-center',
  feedbackRow: 'flex items-center w-48 m-auto mt-5 mb-5 ml-8',
  rowItem: 'flex-grow w-2/4 cursor-pointer',
};

export const RowLayout = ({
  classes,
  choice,
  onChoiceChangeHandler,
}) => {
  const { tw } = useHMSTheme();
  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-row-layout',
      }),
    [tw, classes],
  );

  return (
    <div className={styler('feedbackRow')}>
      <div className={styler('rowItem')}>
        <label className={styler('checkBoxLabel')}>
          <input
            onChange={onChoiceChangeHandler}
            value={choice}
            type="checkbox"
          />
          <span>{choice}</span>
        </label>
      </div>
    </div>
  );
};
