import './index.css';
import { Fragment, useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { CountDownTimer } from '../CountDownTimer/CountDownTimer';
import { hmsUiClassParserGenerator, date, now } from '../../../common/utils';
import { useHMSTheme } from '@100mslive/hms-video-react';

const defaultClasses = {
  root: 'mr-2 lg:mr-4',
  label: 'inline-block pb-1 pr-2 text-gray-500',
  timer: 'inline-block bg-red-main text-gray-500',
};

export const MeetingEndingCountDown = ({ last: time, event, classes }) => {
  const { tw } = useHMSTheme();

  const rootRef = useRef(null);
  const containerRef = useRef(null);

  const [scheduledEnd, setScheduledEnd] = useState(null);
  const [remaining, setRemaining] = useState(0);
  const [showCountdown, setShowCountdown] = useState(false);
  const [floating, setFloating] = useState(false);
  const [animating, setAnimating] = useState(null);
  const [discardTimer, setDiscardTimer] = useState(null);

  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-mecountdown',
      }),
    [tw, classes],
  );

  const animate = floating => {
    const root = rootRef.current;
    const container = containerRef.current;

    if (!root || !container) {
      return;
    }
    
    if (animating) {
      clearTimeout(animating);
      setAnimating(null);
    }

    if (floating && container) {
      const rect = container.getBoundingClientRect();

      root.style.width = `${rect.width}px`;
      root.style.height = `${rect.height}px`;

      container.style.top = `${rect.top.toFixed(1)}px`;
      container.style.left = `${rect.left.toFixed(1)}px`;
      container.style.position = 'fixed';

      const timer = setTimeout(() => {
        container.style.top = '50%';
        container.style.left = '50%';
        setAnimating(null);
      });

      setAnimating(timer);
    }
    else if (!floating && container) {
      const rect = root.getBoundingClientRect();

      container.style.position = 'fixed';
      container.style.top = `${rect.top.toFixed(1)}px`;
      container.style.left = `${rect.left.toFixed(1)}px`;

      const timer = setTimeout(() => {
        container.style.position = 'static';
        container.style.top = '0px';
        container.style.left = '0px';
        setAnimating(null);
      }, 300);

      setAnimating(timer);
    }
  };

  const setFloatingAnimated = useCallback(value => {
    animate(value);
    setFloating(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const discardFloating = useCallback(() => {
    if (floating) {
      if (discardTimer) {
        clearTimeout(discardTimer);
        setDiscardTimer(null);
      }

      setFloatingAnimated(false);
    }
  }, [discardTimer, floating, setFloatingAnimated]);

  useEffect(() => {
    setScheduledEnd(event ? date(event.end).toDate() : null);
  }, [event]);

  useEffect(() => {
    const evaluate = () => {
      const remaining = scheduledEnd ? -now().subtract(scheduledEnd) : 0;
      const show = scheduledEnd ? remaining <= time : false;
      const value = Math.max(remaining, 0);
  
      setShowCountdown(show);
      setRemaining(value);
    };

    evaluate();

    const timer = setInterval(() => evaluate(), 1000);

    return () => clearInterval(timer);
  }, [scheduledEnd, event, time]);

  useEffect(() => {
    if (!showCountdown) {
      setFloating(false);
      return;
    }

    setFloatingAnimated(true);

    const timer = setTimeout(() => {
      setFloatingAnimated(false);
    }, 5000);

    setDiscardTimer(timer);

    return () => {
      clearTimeout(timer);
      setDiscardTimer(null);
      setFloating(false);
    };
  }, [showCountdown, setFloatingAnimated]);

  return (
    <Fragment>
      {/* root */}
      {showCountdown && (
        <div className={styler('root')} ref={rootRef}>
          {/* container */}
          <div className={floating ? styler('container-floating') : styler('container-static')}
              ref={containerRef} onClick={discardFloating}>
            <span className={styler('label')}>Ending in:</span>
            <CountDownTimer className={styler('timer')} time={remaining} />
          </div>
        </div>
      )}
    </Fragment>
  )
};
