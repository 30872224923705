import React, { Fragment, useCallback } from 'react';
import {
  useHMSStore,
  useHMSActions,
  Button, Text,
  HandFilledIcon, MicOffIcon, MicOnIcon, SettingsIcon,
} from "@100mslive/hms-video-react";
import {
  selectLocalPeer,
  selectIsPeerAudioEnabled,
  selectAudioTrackByPeerID,
  selectPermissions,
} from '@100mslive/hms-video-store';
import { Avatar } from '../Avatar';

const Icons = ({
  styler = () => '',
  peerId,
  onUserSettingsClick,
  isHandRaised,
}) => {
  const localPeer = useHMSStore(selectLocalPeer);
  const permissions = useHMSStore(selectPermissions);
  const isAudioEnabled = useHMSStore(selectIsPeerAudioEnabled(peerId));
  const audioTrack = useHMSStore(selectAudioTrackByPeerID(peerId));

  const hmsActions = useHMSActions();
  const toggleAudio = useCallback(async () => {
    if (!audioTrack) return;
    if (peerId === localPeer.id) {
      try {
        await hmsActions.setLocalAudioEnabled(!isAudioEnabled);
      } catch (err) {
        console.error("Cannot toggle audio", err);
      }
    } else {
      hmsActions.setRemoteTrackEnabled(audioTrack.id, !isAudioEnabled);
    }
  }, [hmsActions, peerId, localPeer, audioTrack, isAudioEnabled]);

  return (
    <Fragment>
      {permissions?.changeRole && (
        <div>
          <Button
            iconOnly
            variant="no-fill"
            shape="circle"
            size="sm"
            onClick={onUserSettingsClick}
            key="settingsIcon"
          >
            <SettingsIcon />
          </Button>
        </div>
      )}
      <div className={isAudioEnabled ? styler('onIcon') : styler('offIcon')}>
        <Button iconOnly shape="circle" size="sm" variant="no-fill" onClick={toggleAudio}>
          {isAudioEnabled ? <MicOnIcon /> : <MicOffIcon />}
        </Button>
      </div>
      {isHandRaised && (
        <div>
          <Button iconOnly shape="circle" size="md" variant="no-fill">
            <HandFilledIcon width="24" height="24" />
          </Button>
        </div>
      )}
    </Fragment>
  );
};

const ListItem = ({
  styler = () => '',
  name,
  avatarUrl,
  peerId,
  onUserSettingsClick,
  isHandRaised,
}) => {
  return (
    <span className={styler('menuItem')} role="menuitem">
      <div className={styler('menuText')}>
        <Avatar
          image={avatarUrl}
          label={name}
          key={name}
          size="sm"
          classes={{ root: 'mr-2' }}
        />
        <Text variant="body" className={styler('expanded')} title={name}>
          {name}
        </Text>
      </div>
      <div className={styler('menuIconContainer')}>
        <Icons
          styler={styler}
          peerId={peerId}
          onUserSettingsClick={onUserSettingsClick}
          isHandRaised={isHandRaised}
        />
      </div>
    </span>
  );
};

export const ParticipantInList = React.memo(
  ListItem,
  (prev, next) => prev.peerId === next.peerId,
);
