import React, { useEffect, useContext, useState, useCallback } from "react";
import urlJoin from 'url-join';
import { AppContext } from "../store/AppContext";
import { useHistory, useParams } from "react-router-dom";
import { ConferenceHeader } from "../views/headerView";
import { ConferenceFooter } from "../views/footerView";
import { ConferenceMainView } from "../views/mainView";
import {
  Button,
  MessageModal,
  selectLocalPeer,
  selectRoomState,
  selectIsConnectedToRoom,
  selectRoleChangeRequest,
  selectRTMPState,
  useHMSActions,
  useHMSStore,
} from "@100mslive/hms-video-react";
import { Notifications } from "../views/components/Notifications/Notifications";
import FullPageProgress from "../views/components/FullPageSpinner";
import { ROLES } from '../common/roles';
import { useMetadata } from '../views/hooks/useMetadata';
import { useSearchParam } from 'react-use';
import { BEAM_BOT } from '../common/constants';
import { joinQueryParams, toQueryString } from '../common/utils';
import { hmsToast } from '../views/components/Notifications/hms-toast';

const beamBotMeetingUrl = joinQueryParams(window.location.href, `${BEAM_BOT}=true`);

export const Conference = () => {
  const history = useHistory();
  const { roomId, authToken } = useParams();
  const beamBot = useSearchParam(BEAM_BOT) === "true";
  const context = useContext(AppContext);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isParticipantListOpen, setIsParticipantListOpen] = useState(false);
  const toggleChat = useCallback(() => {
    setIsChatOpen(open => !open);
  }, []);
  const localPeer = useHMSStore(selectLocalPeer);
  const roomState = useHMSStore(selectRoomState);
  const [isFailedToConnect, setIsFailedToConnect] = useState(false);
  const isConnectedToRoom = useHMSStore(selectIsConnectedToRoom);
  const roleChangeRequest = useHMSStore(selectRoleChangeRequest);
  const rtmp = useHMSStore(selectRTMPState);
  const hmsActions = useHMSActions();
  const { setAvatarUrl } = useMetadata();

  const onParticipantListOpen = useCallback(value => {
    setIsParticipantListOpen(value);
  }, []);

  const redirectTo = (url, params = {}) => {
    const preserve = {};

    if (beamBot) {
      preserve[BEAM_BOT] = beamBot;
    }

    if (Object.keys(preserve).length) {
      params = { ...preserve, ...params };
    }

    const queryParams = toQueryString(params);
    const targetUrl   = joinQueryParams(url, queryParams);

    history.push(targetUrl);
  };

  const { loginInfo } = context;
  const { event, role } = loginInfo;
  const bot = role === ROLES.BOT;

  const startLiveStreaming = async (retry = false) => {
    const meetingURL = beamBotMeetingUrl;
    const record     = !!event.options.recording;
    const rtmpURLs   = [];
    const youtube    = event.livestreaming.youtube || {};

    if (youtube.streamUrl) {
      const url = youtube.streamKey ? urlJoin(youtube.streamUrl, '/' + youtube.streamKey) : youtube.streamUrl;
      rtmpURLs.push(url);
    }

    if (rtmpURLs.length) {
      try {
        await hmsActions.startRTMPOrRecording({
          meetingURL,
          rtmpURLs: rtmpURLs.length ? rtmpURLs : undefined,
          record,
        });
      }
      catch (error) {
        console.error("failed to start live streaming", error);

        if (error?.description === "RTMP stream already running" && !retry) {
          await hmsActions.stopRTMPAndRecording();
          await startLiveStreaming(true);
          return;
        }
  
        hmsToast(error.message);
      }
    }
  };

  useEffect(() => {
    if (!roomId) {
      redirectTo('/');
    }
    if (!loginInfo.token) {
      // redirect to join if token not present
      if (authToken) redirectTo(`/preview/${loginInfo.roomId || roomId || ""}/${authToken}`);
      else redirectTo(`/preview/${loginInfo.roomId || roomId || ""}`);
    }
    return () => {
      // This is needed to handle mac touchpad swipe gesture
      hmsActions.leave();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isConnectedToRoom) {
      setAvatarUrl(loginInfo.profile?.ProfileImageUrl);
    }
    if (isConnectedToRoom && role === ROLES.HOST && event.livestreaming.prepared && !event.livestreaming.finished && !rtmp.running) {
      startLiveStreaming();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnectedToRoom]);

  useEffect(() => {
    if (roomState === 'Failed') {
      setIsFailedToConnect(true);
    }
  }, [roomState]);
  
  const reloadRoom = () => {
    window.location.reload();
  };

  if (!isConnectedToRoom && !isFailedToConnect) {
    return <FullPageProgress />;
  }

  if (isFailedToConnect) {
    return (
      <MessageModal
        title="Unable to join room"
        body="An error occurred while connecting to server, please try again later."
        footer={(
          <Button classes={{ root: 'mr-2' }} onClick={reloadRoom}>Reload</Button>
        )}
      />
    );
  }

  return (
    <div className="w-full h-full flex flex-col dark:bg-black">
      {!bot && (
        <div className="h-14 md:h-16">
          <ConferenceHeader isChatOpen={isChatOpen} onParticipantListOpen={onParticipantListOpen} />
        </div>
      )}
      <div className="w-full flex flex-1 flex-col md:flex-row">
        <ConferenceMainView
          event={event}
          isChatOpen={isChatOpen}
          isParticipantListOpen={isParticipantListOpen}
          toggleChat={toggleChat}
        />
        <Notifications />
      </div>
      {!bot && (
        <div className="dark:bg-black" style={{ height: "10%" }}>
          <ConferenceFooter event={event} role={role} isChatOpen={isChatOpen} toggleChat={toggleChat} />
        </div>
      )}
      <MessageModal
        show={!!roleChangeRequest}
        onClose={() => hmsActions.rejectChangeRole(roleChangeRequest)}
        title="Role Change Request"
        body={`Role change requested by ${roleChangeRequest?.requestedBy?.name}.
              Changing role to ${roleChangeRequest?.role?.name}.`}
        footer={
          <div className="flex space-x-1">
            <Button
              onClick={async () => {
                await hmsActions.acceptChangeRole(roleChangeRequest);

                if (roleChangeRequest?.role?.name === ROLES.PRESENTER && localPeer.roleName === ROLES.PRESENTER) {
                  await hmsActions.setScreenShareEnabled(true);
                }
              }}
            >
              Accept
            </Button>
          </div>
        }
      />
    </div>
  );
};
