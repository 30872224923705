import React, { useMemo } from 'react';
import { useHMSTheme, Thumbsup, Thumbsdown } from '@100mslive/hms-video-react';
import { hmsUiClassParserGenerator } from '../../../common/utils';

const defaultClasses = {
  feedbackSection: 'flex justify-evenly mt-5',
  feedbackIconSection: 'w-full h-24 mt-10 mb-2',
  feedbackColumn: 'flex-col text-center justify-evenly cursor-pointer',
  footerFeedback: 'flex  mr-40 space-x-5',
};

export const FeedbackPopup = ({
  classes,
  setShowModal = () => {},
  getFeedbackHandler = () => {},
  getPostiveFeedbackHandler = () => {},
}) => {
  const { tw } = useHMSTheme();
  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-feedback-popup',
      }),
    [tw, classes],
  );
  const IconStyles = { backgroundColor: '#fff', clipPath: 'circle(49%)' };
  return (
    <div className={styler('feedbackSection')}>
      <div
        onClick={getPostiveFeedbackHandler}
        className={styler('feedbackColumn')}
      >
        <Thumbsup style={IconStyles} />
        <div>Good</div>
      </div>
      <div onClick={getFeedbackHandler} className={styler('feedbackColumn')}>
        <Thumbsdown style={IconStyles} />
        <div>Bad</div>
      </div>
    </div>
  );
};
