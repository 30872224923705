import React from 'react';
import { useHMSTheme, Logo } from "@100mslive/hms-video-react";

export const LogoButton = () => {
  let logo, logoClass;
  try {
    const { appBuilder } = useHMSTheme(); // eslint-disable-line react-hooks/rules-of-hooks
    logo = appBuilder.logo;
    logoClass = appBuilder.logoClass;
  } catch (e) {}
  return (
    <button className=" p-2 focus:outline-none">
      {Boolean(logo) ? (
        <img
          src={logo}
          alt="brand_logo"
          // className=" md:object-contain object-scale-down md:h-full"
          className={`object-contain flex justify-center ${
            logoClass ? logoClass : 'h-6'
          }`}
        />
      ) : (
        <Logo />
      )}
    </button>
  );
};