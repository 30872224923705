import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import {
  HMSRoomProvider,
  HMSThemeProvider,
  // PostLeaveDisplay,
} from "@100mslive/hms-video-react";
import PreviewScreen from "./pages/PreviewScreen";
import { Conference } from "./pages/ConferencePage.jsx";
import ErrorPage from "./pages/ErrorPage";
import { AppContextProvider } from "./store/AppContext.js";
import { shadeColor, getBrokerloopLinkTo } from "./common/utils";
import {
  getUserToken as defaultGetUserToken,
  getBackendEndpoint,
} from "./services/tokenService";
import { sendUserFeedback } from './services/feedbackService';
import { hmsToast } from "./views/components/Notifications/hms-toast";
import { LeaveRoomOnExit } from "./views/components/LeaveRoomOnExit";
import { PostLeaveDisplay } from './views/components/PostLeaveDisplay/PostLeaveDisplay';

const defaultTokenEndpoint = getBackendEndpoint();
const envPolicyConfig = JSON.parse(process.env.REACT_APP_POLICY_CONFIG || "{}");

export function EdtechComponent({
  roomId = "",
  tokenEndpoint = defaultTokenEndpoint,
  themeConfig: {
    aspectRatio = "1-1",
    font = "Roboto",
    color = "#2F80FF",
    theme = "dark",
    showChat = "true",
    showScreenshare = "true",
    logo = "",
    showAvatar = "true",
    avatarType = "initial",
    headerPresent = "false",
    logoClass = "",
  },
  getUserToken = defaultGetUserToken,
  policyConfig = envPolicyConfig,
}) {
  const { 0: width, 1: height } = aspectRatio
    .split("-")
    .map(el => parseInt(el));
  return (
    <div
      className={`w-full dark:bg-black ${headerPresent === "true" ? "flex-1" : "h-full"
        }`}
    >
      <HMSThemeProvider
        config={{
          theme: {
            extend: {
              fontFamily: {
                sans: [font, "Inter", "sans-serif"],
                body: [font, "Inter", "sans-serif"],
              },
              colors: {
                brand: {
                  main: color,
                  tint: shadeColor(color, 30),
                },
              },
            },
          },
        }}
        appBuilder={{
          theme: theme || "dark",
          enableChat: showChat === "true",
          enableScreenShare: showScreenshare === "true",
          logo: logo,
          logoClass: logoClass,
          headerPresent: headerPresent === "true",
          videoTileAspectRatio: { width, height },
          showAvatar: showAvatar === "true",
          avatarType: avatarType,
        }}
        toast={(message, options = {}) => hmsToast(message, options)}
      >
        <HMSRoomProvider>
          <AppContextProvider
            roomId={roomId}
            tokenEndpoint={tokenEndpoint}
            policyConfig={policyConfig}
          >
            <LeaveRoomOnExit />
            <Router>
              <Switch>
                <Route
                  path="/preview/:roomId/:authToken?"
                  render={({ match }) => {
                    const { params } = match;
                    if (!params.roomId && !params.authToken) {
                      return <Redirect to="/" />;
                    }
                    if (
                      !params.roomId ||
                      ["preview", "meeting", "leave"].includes(params.roomId)
                    ) {
                      return <Redirect to="/" />;
                    }
                    return <PreviewScreen getUserToken={getUserToken} />;
                  }}
                />
                <Route path="/meeting/:roomId/:authToken?">
                  <Conference />
                </Route>
                <Route
                  path="/leave/:roomId/:authToken?"
                  render={({ history, match }) => (
                    <PostLeaveDisplay
                      goToBrokerloopOnClick={event => {
                        const eventUrl = getBrokerloopLinkTo(event);
                        window.location = eventUrl;
                      }}
                      joinRoomOnClick={() => {
                        let previewUrl = "/preview/" + match.params.roomId;
                        if (match.params.authToken)
                          previewUrl += "/" + match.params.authToken;
                        window.location = previewUrl;
                      }}
                      submitFeedbackOnClick={data => {
                        const { username, comment, choices } = data;
                        const { authToken } = match.params;
                        sendUserFeedback(username, comment, choices, authToken);
                      }}
                    />
                  )}
                />
                <Route
                  path="/:roomId/:authToken?"
                  render={({ match }) => {
                    const { params } = match;
                    if (!params.roomId && !params.authToken) {
                      return <Redirect to="/" />;
                    }
                    if (!params.roomId) {
                      return <Redirect to="/" />;
                    }
                    return (
                      <Redirect
                        to={`/preview/${params.roomId}/${params.authToken || ""}`}
                      />
                    );
                  }}
                />
                <Route
                  path="*"
                  render={() => <ErrorPage error="Invalid URL." />}
                />
              </Switch>
            </Router>
          </AppContextProvider>
        </HMSRoomProvider>
      </HMSThemeProvider>
    </div>
  );
}

export default function App() {
  return (
    <EdtechComponent
      themeConfig={{
        aspectRatio: process.env.REACT_APP_TILE_SHAPE,
        theme: process.env.REACT_APP_THEME,
        color: process.env.REACT_APP_COLOR,
        logo: process.env.REACT_APP_LOGO,
        font: process.env.REACT_APP_FONT,
        showChat: process.env.REACT_APP_SHOW_CHAT,
        showScreenshare: process.env.REACT_APP_SHOW_SCREENSHARE,
        showAvatar: process.env.REACT_APP_VIDEO_AVATAR,
        avatarType: process.env.REACT_APP_AVATAR_TYPE,
        logoClass: process.env.REACT_APP_LOGO_CLASS,
        headerPresent: process.env.REACT_APP_HEADER_PRESENT,
      }}
      getUserToken={defaultGetUserToken}
    />
  );
}
