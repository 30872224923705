import React, { useMemo } from 'react';
import { useHMSTheme, Text } from "@100mslive/hms-video-react";
import { hmsUiClassParserGenerator, truncate } from '../../../common/utils';

const defaultClasses = {
  root: 'h-full text-white flex items-center justify-center',
  rootBg:
    'w-37.5 h-42.5 rounded-2xl relative flex flex-col text-center space-y-4 justify-center items-center bg-contain',
  // content: 'flex flex-col transform -translate-y-full mls:translate-y-0',
  content: 'flex flex-col transform md:-translate-y-20 mls:translate-y-0',
  footer: 'flex flex-row'
};

export const WaitingScreenDisplay = ({
  event = {},
  username = '',
  classes,
  src,
  message = '',
  footer,
}) => {
  const { tw } = useHMSTheme();
  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-waiting-screen',
      }),
    [tw, classes],
  );

  return (
    <div className={styler('root')}>
      <div
        className={styler('rootBg')}
        style={{
          backgroundColor: '#333333',
          backgroundImage: src ? `url(${src})` : 'none',
        }}
      >
        <div className={styler('content')}>
          <Text tag="h1" variant="heading">
            Welcome {username} !
          </Text>
          {message && (
            <div className="mt-4">{message}</div>
          )}
          {event && (
            <div className="mt-8 px-6">
              {event.name && (
                <Text tag="h2" variant="heading" size="sm">
                  Event: {event.name}
                </Text>
              )}
              {event.description && (
                <Text tag="p" variant="heading" size="sm" style={{color: '#6e6e6e'}}>
                    {truncate(event.description, 700)}
                </Text>
              )}
            </div>
          )}
        </div>
        {footer && (
          <div className={styler('footer')}>
            {footer}
          </div>
        )}
      </div>
    </div>
  );
};
