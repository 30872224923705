import { hmsToast } from "../views/components/Notifications/hms-toast";
import screenfull from "screenfull";
import { apply } from 'twind';
import { validate as validateUuid } from 'uuid';
import moment from 'moment';
import { ORIGIN } from './constants';

export function shadeColor(color, percent) {
  let R = parseInt(color.substring(1, 3), 16);
  let G = parseInt(color.substring(3, 5), 16);
  let B = parseInt(color.substring(5, 7), 16);

  R = Math.floor((R * (100 + percent)) / 100);
  G = Math.floor((G * (100 + percent)) / 100);
  B = Math.floor((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  const RR =
    R.toString(16).length === 1 ? "0" + R.toString(16) : R.toString(16);
  const GG =
    G.toString(16).length === 1 ? "0" + G.toString(16) : G.toString(16);
  const BB =
    B.toString(16).length === 1 ? "0" + B.toString(16) : B.toString(16);

  return "#" + RR + GG + BB;
}

/**
 * @param {boolean} isParticipantListOpen
 * @param {number} totalPeers
 * @returns {string}
 * This util is to add blur to chatbox when participants are more than 4 below 1024 and
 * more than 7 above 1024 screens
 */
export function getBlurClass(isParticipantListOpen, totalPeers) {
  const OVERLAP_THRESHOLD = window.innerHeight >= 1024 ? 7 : 4;
  return isParticipantListOpen && totalPeers > OVERLAP_THRESHOLD
    ? "filter blur-sm"
    : "";
}

/**
 * TODO: this is currently an O(N**2) function, don't use with peer lists, it's currently
 * being used to find intersection between list of role names where the complexity shouldn't matter much.
 */
export const arrayIntersection = (a, b) => {
  if (a === undefined || b === undefined) {
    return [];
  }
  var t;
  if (a === undefined || b === undefined) {
    return [];
  }
  if (b.length > a.length) {
    t = b;
    b = a;
    a = t;
  }
  return a.filter(function (e) {
    return b.indexOf(e) > -1;
  });
};

/**
 * @param {boolean} setFullScreen
 * @return {void}
 * @desc This util function toggles the full screen based on setFullScreen parameter.
 * */
export const setFullScreenEnabled = async setFullScreen => {
  if (setFullScreen) {
    try {
      if (screenfull.isEnabled) {
        await screenfull.request();
      }
    } catch (error) {
      hmsToast(error.message);
    }
  } else {
    try {
      await screenfull.exit();
    } catch (error) {
      hmsToast(error.message);
    }
  }
};

export const resolveClasses = (
  defaults,
  custom
) => {
  if (!custom) {
    return defaults;
  }

  const hash = { ...defaults };

  for (const k in defaults) {
    if (custom.hasOwnProperty(k)) {
      Object.assign(hash, { [k]: `${defaults[k]} ${custom[k]}` });
    }
  }

  return hash;
};

const defaultStyler = (defaultClasses, key) => {
  return defaultClasses[key] ? defaultClasses[key] : '';
};

export const hmsUiClassParserGenerator = ({
  tw,
  classes,
  customClasses,
  defaultClasses,
  tag,
}) => (key) => {
  const finalClasses = resolveClasses(defaultClasses, classes);
  if (tw) {
    return tw(
      `${tag}-${key}`,
      customClasses && customClasses[key],
      apply(finalClasses[key]),
    );
  }
  // handle edge case of tw not being present
  else {
    return defaultStyler(defaultClasses, key);
  }
};

export const isBrowser = typeof window !== 'undefined';

export const likeUuid = str => {
  // e.g. 20b46a1f-3493-47e1-8804-6101dcf6722f
  return str ? validateUuid(str) : false;
};

export const pluralize = (count, noun, suffix = 's') => {
  return noun ? `${count || 0} ${noun}${count !== 1 ? suffix : ''}` : count;
};

export const toPascalCase = str => {
  const words = typeof str === 'string' ? str.match(/[a-z]+/gi) : '';
  if (!words) {
    return '';
  }
  return words
    .map(word => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase())
    .join('');
};

export const groupByRole = peers => {
  const res = [];
  const roleMap = {};
  for (const peer of peers) {
    if (peer.roleName) {
      if (!roleMap[peer.roleName]) {
        roleMap[peer.roleName] = [];
      }
      roleMap[peer.roleName].push(peer);
    }
  }
  for (const role in roleMap) {
    if (roleMap[role].length) {
      res.push([role, roleMap[role]]);
    }
  }
  return res;
};

/**
 * Given a list of tracks/elements and some constraints, group the tracks in separate pages.
 * @return 2D list for every page which has the original element and height and width
 * for its tile.
 */
 export const chunkElements = ({
  elements,
  tilesInFirstPage,
  onlyOnePage,
  isLastPageDifferentFromFirstPage,
  defaultWidth,
  defaultHeight,
  lastPageWidth,
  lastPageHeight,
}) => {
  const chunks = chunk(elements, tilesInFirstPage, onlyOnePage);
  return chunks.map((chunk, page) => {
    return chunk.map(element => {
      const isLastPage = page === chunks.length - 1;
      const width =
        isLastPageDifferentFromFirstPage && isLastPage
          ? lastPageWidth
          : defaultWidth;
      const height =
        isLastPageDifferentFromFirstPage && isLastPage
          ? lastPageHeight
          : defaultHeight;
      return { ...element, height, width };
    });
  });
};

export const chunk = (
  elements,
  chunkSize,
  onlyOnePage,
) => {
  return elements.reduce((resultArray, tile, index) => {
    const chunkIndex = Math.floor(index / chunkSize);
    if (chunkIndex > 0 && onlyOnePage) {
      return resultArray;
    }
    if (!resultArray[chunkIndex]) {
      resultArray[chunkIndex] = []; // start a new chunk
    }

    resultArray[chunkIndex].push(tile);
    return resultArray;
  }, []);
};

/**
 * get the aspect ration occurring with the highest frequency
 * @param tracks - video tracks to infer aspect ratios from
 */
export const getModeAspectRatio = tracks => {
  return mode(
    tracks
      .filter(track => track.track?.width && track.track?.height)
      .map(track => {
        const width = track.track?.width;
        const height = track.track?.height;
        //Default to 1 if there are no video tracks
        return (width ? width : 1) / (height ? height : 1);
      }),
  );
};

/**
 * Mathematical mode - the element with the highest occurrence in an array
 * @param array
 */
 export function mode(array) {
  if (array.length === 0) {
    return null;
  }
  const modeMap = {};
  let maxEl = array[0];
  let maxCount = 1;
  for (let i = 0; i < array.length; i++) {
    const el = array[i];
    if (modeMap[el] === null) {
      modeMap[el] = 1;
    } else {
      modeMap[el]++;
    }
    if (modeMap[el] > maxCount) {
      maxEl = el;
      maxCount = modeMap[el];
    }
  }
  return maxEl;
}

export function calculateLayoutSizes({
  count,
  parentWidth,
  parentHeight,
  maxTileCount,
  maxRowCount,
  maxColCount,
  aspectRatio,
}) {
  let defaultWidth = 0;
  let defaultHeight = 0;
  let lastPageWidth = 0;
  let lastPageHeight = 0;
  let isLastPageDifferentFromFirstPage = false;
  let tilesInFirstPage = 0;

  if (!count) {
    // no tracks to show
    return {
      tilesInFirstPage,
      defaultWidth,
      defaultHeight,
      lastPageWidth,
      lastPageHeight,
      isLastPageDifferentFromFirstPage,
    };
  }

  if (maxTileCount) {
    ({
      tilesInFirstPage,
      defaultWidth,
      defaultHeight,
      lastPageWidth,
      lastPageHeight,
      isLastPageDifferentFromFirstPage,
    } = getTileSizesWithPageConstraint({
      parentWidth,
      parentHeight,
      count,
      maxCount: maxTileCount,
      aspectRatio,
    }));
  } else if (maxRowCount) {
    ({
      tilesInFirstPage,
      defaultWidth,
      defaultHeight,
      lastPageWidth,
      lastPageHeight,
      isLastPageDifferentFromFirstPage,
    } = getTileSizesWithRowConstraint({
      parentWidth,
      parentHeight,
      count,
      maxCount: maxRowCount,
      aspectRatio,
    }));
  } else if (maxColCount) {
    ({
      tilesInFirstPage,
      defaultWidth,
      defaultHeight,
      lastPageWidth,
      lastPageHeight,
      isLastPageDifferentFromFirstPage,
    } = getTileSizesWithColConstraint({
      parentWidth,
      parentHeight,
      count,
      maxCount: maxColCount,
      aspectRatio,
    }));
  } else {
    const { width, height } = largestRect(
      parentWidth,
      parentHeight,
      count,
      aspectRatio.width,
      aspectRatio.height,
    );
    defaultWidth = width;
    defaultHeight = height;
    tilesInFirstPage = count;
  }
  return {
    tilesInFirstPage,
    defaultWidth,
    defaultHeight,
    lastPageWidth,
    lastPageHeight,
    isLastPageDifferentFromFirstPage,
  };
};

export const getTileSizesWithColConstraint = ({
  parentWidth,
  parentHeight,
  count,
  maxCount,
  aspectRatio,
}) => {
  let defaultWidth = 0;
  let defaultHeight = 0;
  let lastPageWidth = 0;
  let lastPageHeight = 0;
  let isLastPageDifferentFromFirstPage = false;
  let tilesInFirstPage = 0;
  let tilesinLastPage = 0;
  const cols = Math.min(
    Math.ceil(
      Math.sqrt(
        (count * (parentWidth / parentHeight)) /
          (aspectRatio.width / aspectRatio.height),
      ),
    ),
    maxCount,
  );
  const width = parentWidth / cols;
  const height = width / (aspectRatio.width / aspectRatio.height);
  const rows = Math.floor(parentHeight / height);
  defaultHeight = height;
  defaultWidth = width;
  tilesInFirstPage = Math.min(count, rows * cols);
  tilesinLastPage = count % (rows * cols);
  isLastPageDifferentFromFirstPage = tilesinLastPage > 0 && count > rows * cols;
  if (isLastPageDifferentFromFirstPage) {
    const cols = Math.min(
      Math.ceil(
        Math.sqrt(
          (tilesinLastPage * (parentWidth / parentHeight)) /
            (aspectRatio.width / aspectRatio.height),
        ),
      ),
      maxCount,
    );
    const width = parentWidth / cols;
    const height = width / (aspectRatio.width / aspectRatio.height);
    lastPageHeight = height;
    lastPageWidth = width;
  }
  return {
    tilesInFirstPage,
    defaultWidth,
    defaultHeight,
    lastPageWidth,
    lastPageHeight,
    isLastPageDifferentFromFirstPage,
  };
};

export const getTileSizesWithPageConstraint = ({
  parentWidth,
  parentHeight,
  count,
  maxCount,
  aspectRatio,
}) => {
  let defaultWidth = 0;
  let defaultHeight = 0;
  let lastPageWidth = 0;
  let lastPageHeight = 0;
  let isLastPageDifferentFromFirstPage = false;
  let tilesInFirstPage = 0;
  let tilesinLastPage = 0;
  const { width: initialWidth, height: initialHeight } = largestRect(
    parentWidth,
    parentHeight,
    Math.min(count, maxCount),
    aspectRatio.width,
    aspectRatio.height,
  );
  defaultWidth = initialWidth;
  defaultHeight = initialHeight;
  tilesInFirstPage = Math.min(count, maxCount);
  tilesinLastPage = count % maxCount;
  isLastPageDifferentFromFirstPage = tilesinLastPage > 0 && count > maxCount;
  if (isLastPageDifferentFromFirstPage) {
    const { width: remWidth, height: remHeight } = largestRect(
      parentWidth,
      parentHeight,
      tilesinLastPage,
      aspectRatio.width,
      aspectRatio.height,
    );
    lastPageWidth = remWidth;
    lastPageHeight = remHeight;
  }
  return {
    tilesInFirstPage,
    defaultWidth,
    defaultHeight,
    lastPageWidth,
    lastPageHeight,
    isLastPageDifferentFromFirstPage,
  };
};

/**
 * Finds the largest rectangle area when trying to place N rectangle into a containing
 * rectangle without rotation.
 *
 * @param {Number}  containerWidth      The width of the container.
 * @param {Number}  containerHeight     The height of the container.
 * @param {Number}  numRects            How many rectangles must fit within.
 * @param {Number}  width               The unscaled width of the rectangles to be placed.
 * @param {Number}  height              The unscaled height of the rectangles to be placed.
 * @return {Object}                     The area and number of rows and columns that fit.
 */
 export const largestRect = (
  containerWidth,
  containerHeight,
  numRects,
  width,
  height,
) => {
  if (containerWidth < 0 || containerHeight < 0) {
    throw new Error('Container must have a non-negative area');
  }
  if (numRects < 1 || !Number.isInteger(numRects)) {
    throw new Error('Number of shapes to place must be a positive integer');
  }
  const aspectRatio = width && height && width / height;
  if (aspectRatio !== undefined && isNaN(aspectRatio)) {
    throw new Error('Aspect ratio must be a number');
  }

  let best = { area: 0, cols: 0, rows: 0, width: 0, height: 0 };

  // TODO: Don't start with obviously-`ba`d candidates.
  const startCols = numRects;
  const colDelta = -1;

  // For each combination of rows + cols that can fit the number of rectangles,
  // place them and see the area.
  if (aspectRatio !== undefined) {
    for (let cols = startCols; cols > 0; cols += colDelta) {
      const rows = Math.ceil(numRects / cols);
      const hScale = containerWidth / (cols * aspectRatio);
      const vScale = containerHeight / rows;
      let width;
      let height;
      // Determine which axis is the constraint.
      if (hScale <= vScale) {
        width = containerWidth / cols;
        height = width / aspectRatio;
      } else {
        height = containerHeight / rows;
        width = height * aspectRatio;
      }
      const area = width * height;
      if (area > best.area) {
        best = { area, width, height, rows, cols };
      }
    }
  }
  return best;
};

export const getTileSizesWithRowConstraint = ({
  parentWidth,
  parentHeight,
  count,
  maxCount,
  aspectRatio,
}) => {
  let defaultWidth = 0;
  let defaultHeight = 0;
  let lastPageWidth = 0;
  let lastPageHeight = 0;
  let isLastPageDifferentFromFirstPage = false;
  let tilesInFirstPage = 0;
  let tilesinLastPage = 0;
  const rows = Math.min(
    Math.ceil(
      Math.sqrt(
        (count * (aspectRatio.width / aspectRatio.height)) /
          (parentWidth / parentHeight),
      ),
    ),
    maxCount,
  );
  const height = parentHeight / rows;
  const width = height * (aspectRatio.width / aspectRatio.height);
  const cols = Math.floor(parentWidth / width);
  defaultWidth = width;
  defaultHeight = height;
  tilesInFirstPage = Math.min(count, rows * cols);
  tilesinLastPage = count % (rows * cols);
  isLastPageDifferentFromFirstPage = tilesinLastPage > 0 && count > rows * cols;
  if (isLastPageDifferentFromFirstPage) {
    const rows = Math.min(
      Math.ceil(
        Math.sqrt(
          (tilesinLastPage * (aspectRatio.width / aspectRatio.height)) /
            (parentWidth / parentHeight),
        ),
      ),
      maxCount,
    );
    const height = parentHeight / rows;
    const width = height * (aspectRatio.width / aspectRatio.height);
    lastPageHeight = height;
    lastPageWidth = width;
  }
  return {
    tilesInFirstPage,
    defaultWidth,
    defaultHeight,
    lastPageWidth,
    lastPageHeight,
    isLastPageDifferentFromFirstPage,
  };
};

export const trimEnding = (str, ending, count = Infinity) => {
  if (!str || !ending) return str;
  if (str.length < ending.length) return str;

  let limit = Math.max(0, count) || str.length;
  let result = str, found = false;

  do {
    const index = result.length - ending.length;
    const strEnding = result.substr(index);

    found = ending === strEnding;

    if (found) {
      result = result.substr(0, index);
    }
  }
  while (found && --limit > 0);

  return result;
};

export const truncate = (str, len, ellipsis = '...') => {
  if (!str) return str;

  if (str.length <= len) return str;
  if (str.length <= ellipsis.length) return str.substr(0, len); // str too short, truncate w/o ellipsis

  return str.substr(0, len - ellipsis.length) + ellipsis;
};

export const getMetadata = metadataString => {
  try {
    if (metadataString === undefined || metadataString === "") {
      return {};
    }
    const metadataObject = typeof metadataString === "object" ? metadataString : JSON.parse(metadataString);
    return metadataObject;
  } catch (error) {
    return {};
  }
};

export const metadataProps = function (peer) {
  return getMetadata(peer.metadata);
};

export const date = (value) => {
  return value === null ? null : moment(value);
};

export const now = () => {
  return moment();
};

export const isToday = (value) => {
  return value ? date(value).isSame(new Date(), 'day') : false;
};

export function getBrokerloopLinkTo(event) {
  const blnBaseUrl = getBrokerloopNetworkEndpoint();

  if (event.networkId) {
    const blnEventUrl = joinQueryParams(blnBaseUrl, `active-group=event&event=${encodeURIComponent(event.networkId)}`);
    return blnEventUrl;
  }
  else {
    return blnBaseUrl;
  }
}

export function getBrokerloopNetworkEndpoint() {
  const originFromStorage = localStorage.getItem(ORIGIN);

  if (originFromStorage) {
    return originFromStorage;
  }

  let baseUrl = '';

  const env = process.env.REACT_APP_ENV || "dev";

  if (env === "prod") {
    baseUrl = process.env.REACT_APP_PROD_BLN_URL || 'https://brokerloop.network/';
  }
  else if (env === "stg") {
    baseUrl = process.env.REACT_APP_STG_BLN_URL || 'https://brokerloop.network/version-test/';
  }
  else {
    baseUrl = process.env.REACT_APP_BLN_URL || 'https://brokerloop.network/version-test/';
  }

  return baseUrl;
}

export const toQueryString = (params) => {
  if (!params || !Object.keys(params).length) {
    return '';
  }

  const queryParams = new URLSearchParams();

  for (const key in params) {
    const value = params[key];
    queryParams.set(key, value);
  }

  return queryParams.toString();
};

export const joinQueryParams = (url, queryParams) => {
  // normalize query params
  queryParams = queryParams || '';
  queryParams = queryParams[0] === '?' || queryParams === '&' ? queryParams.substr(1) : queryParams;

  const delimiter = url.indexOf('?') === -1 ? '?' : '&';
  const result    = url + (queryParams ? delimiter + queryParams : '');
  return result;
};

export const parseOS = (userAgent) => {
  const macosPlatforms = /(macintosh|macintel|macppc|mac68k|macos)/i;
  const windowsPlatforms = /(win32|win64|windows|wince)/i;
  const iosPlatforms = /(iphone|ipad|ipod)/i;
  
  let os = null;

  if (macosPlatforms.test(userAgent)) {
    os = "macos";
  } else if (iosPlatforms.test(userAgent)) {
    os = "ios";
  } else if (windowsPlatforms.test(userAgent)) {
    os = "windows";
  } else if (/android/.test(userAgent)) {
    os = "android";
  } else if (!os && /linux/.test(userAgent)) {
    os = "linux";
  }

  return os;
};
