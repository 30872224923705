import './index.css';
import { useState, useMemo, useEffect, Fragment } from 'react';
import { useHMSTheme } from '@100mslive/hms-video-react';
import { hmsUiClassParserGenerator, date } from '../../../common/utils';

const defaultClasses = {
  root: 'inline-block p-2 font-bold bg-red-main text-white',
};

export const CountDownTimer = ({
  time,
  classes,
}) => {
  const { tw } = useHMSTheme();

  const [remaining, setRemaining] = useState(date(0));

  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-countdowntimer',
      }),
    [tw, classes],
  );

  useEffect(() => {
    setRemaining(date(time || 0));
  }, [time]);

  return (
    <Fragment>
      {/* root */}
      {remaining && (
        <span className={styler('root')}>
          {remaining.format('mm:ss')}
        </span>
      )}
    </Fragment>
  );
};
