import React, { useMemo } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { hmsUiClassParserGenerator, chunkElements,
  getModeAspectRatio,
  calculateLayoutSizes,
  metadataProps, } from '../../../common/utils';
import { VideoTile, Carousel, useHMSVanillaStore, useHMSTheme } from '@100mslive/hms-video-react';
import { selectTracksMap } from '@100mslive/hms-video-store';
import { getVideoTracksFromPeers } from './videoListUtils';
import { Avatar } from '../Avatar';

const defaultClasses = {
  root:
    'relative h-full w-full flex flex-wrap justify-center content-evenly justify-items-center bg-white dark:bg-black',
  sliderRoot: 'w-full h-full',
  sliderInner: 'w-full h-full',
  listContainer:
    'relative h-full w-full flex flex-wrap justify-center items-center content-center',
  videoTileContainer: 'flex justify-center p-3',
};

export const VideoList = ({
  peers,
  showScreenFn = () => true,
  overflow = 'scroll-x',
  maxTileCount,
  tileArrangeDirection = 'row',
  objectFit = 'cover',
  aspectRatio,
  displayShape = 'rectangle',
  audioLevelDisplayType,
  showAudioLevel,
  maxRowCount,
  maxColCount,
  videoTileControls,
  showAudioMuteStatus = true,
  classes,
  videoTileClasses,
  allowRemoteMute,
  compact = false,
  showTileForAllPeers = false,
  videoTileProps,
}) => {
  const { tw, appBuilder } = useHMSTheme();
  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-videoList',
      }),
    [tw, classes],
  );
  const store = useHMSVanillaStore();
  const tracksMap = store.getState(
    selectTracksMap,
  );
  const { width = 0, height = 0, ref } = useResizeDetector();

  if (aspectRatio === undefined) {
    aspectRatio = appBuilder.videoTileAspectRatio;
  }
  aspectRatio =
    displayShape === 'circle' ? { width: 1, height: 1 } : aspectRatio;

  const tracksWithPeer = getVideoTracksFromPeers(
    peers,
    tracksMap,
    showScreenFn,
    showTileForAllPeers,
  );

  const finalAspectRatio = useMemo(() => {
    if (aspectRatio) {
      return aspectRatio;
    } else {
      const modeAspectRatio = getModeAspectRatio(tracksWithPeer);
      //Default to 1 if there are no video tracks
      return {
        width: modeAspectRatio ? modeAspectRatio : 1,
        height: 1,
      };
    }
  }, [aspectRatio, tracksWithPeer]);

  const count = tracksWithPeer.length;
  const {
    tilesInFirstPage,
    defaultWidth,
    defaultHeight,
    lastPageWidth,
    lastPageHeight,
    isLastPageDifferentFromFirstPage,
  } = useMemo(() => {
    //Flooring since there's a bug in react-slick where it converts widdh into a number
    return calculateLayoutSizes({
      count,
      parentWidth: Math.floor(width),
      parentHeight: Math.floor(height),
      maxTileCount,
      maxRowCount,
      maxColCount,
      aspectRatio: finalAspectRatio,
    });
  }, [
    count,
    width,
    height,
    maxTileCount,
    maxRowCount,
    maxColCount,
    finalAspectRatio,
  ]);

  const chunkedTracksWithPeer = useMemo(() => {
    return chunkElements({
      elements: tracksWithPeer,
      tilesInFirstPage,
      onlyOnePage: overflow === 'hidden',
      isLastPageDifferentFromFirstPage,
      defaultWidth,
      defaultHeight,
      lastPageWidth,
      lastPageHeight,
    });
  }, [tracksWithPeer, tilesInFirstPage, overflow, isLastPageDifferentFromFirstPage, defaultWidth, defaultHeight, lastPageWidth, lastPageHeight]);

  return (
    <div className={`${styler('root')}`}>
      <Carousel ref={ref}>
        {chunkedTracksWithPeer &&
          chunkedTracksWithPeer.length > 0 &&
          chunkedTracksWithPeer.map((tracksPeersOnOnePage, page) => {
            return (
              <div className={`${styler('sliderInner')}`} key={page}>
                <div
                  className={` ${styler('listContainer')}   flex-${
                    maxRowCount
                      ? 'col'
                      : maxColCount
                      ? 'row'
                      : tileArrangeDirection
                  } `}
                >
                  {tracksPeersOnOnePage.map((trackPeer, index) => {
                    const metadata = metadataProps(trackPeer.peer);
                    const additionalProps = videoTileProps
                      ? videoTileProps(trackPeer.peer, trackPeer.track)
                      : {};
                    return (
                      <div
                        key={
                          trackPeer.track
                            ? trackPeer.track.id
                            : trackPeer.peer.id
                        } // track id changes on replace track
                        style={{
                          height: trackPeer.height,
                          width: trackPeer.width,
                        }}
                        className={`${styler('videoTileContainer')}`}
                      >
                        <VideoTile
                          peer={trackPeer.peer}
                          hmsVideoTrackId={trackPeer.track?.id}
                          objectFit={objectFit}
                          displayShape={displayShape}
                          audioLevelDisplayType={audioLevelDisplayType}
                          allowRemoteMute={allowRemoteMute}
                          showAudioLevel={showAudioLevel}
                          showAudioMuteStatus={showAudioMuteStatus}
                          aspectRatio={aspectRatio}
                          classes={videoTileClasses}
                          controlsComponent={
                            videoTileControls && videoTileControls[index]
                          }
                          customAvatar={
                            <Avatar
                              image={metadata.avatarUrl}
                              label={trackPeer.peer.name}
                              size="lg"
                            />
                          }
                          compact={compact}
                          {...additionalProps}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
      </Carousel>
    </div>
  );
};
