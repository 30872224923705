import React, { useEffect, useState, useCallback, useMemo, useRef, Fragment } from 'react';
import {
  HMSRoomState,
  selectIsLocalAudioEnabled,
  selectIsLocalVideoDisplayEnabled,
  selectLocalPeer,
  selectRoomState,
  selectIsAllowedToPublish,
} from '@100mslive/hms-video-store';
import { Button, Text, VideoTile, ProgressIcon, DotIcon, PersonIcon, useHMSActions, useHMSStore, useHMSTheme, isMobileDevice, isSafari } from "@100mslive/hms-video-react";
import { Input } from './components/Input';
import { isBrowser, hmsUiClassParserGenerator, trimEnding, truncate, date, now } from '../common/utils';
import { PreviewControls } from './components/Preview/PreviewControls';
import { Avatar } from './components/Avatar';
import { ROLES } from '../common/roles';

const defaultClasses = {
  root: 'flex w-screen h-full mls:h-auto bg-white dark:bg-black justify-center items-center',
  containerRoot:
    'flex flex-col justify-center items-center text-center w-37.5 h-full md:h-400 pb-4 box-border bg-gray-700 dark:bg-gray-100 text-gray-100 dark:text-white overflow-hidden md:rounded-2xl',
  header:
    'w-4/5 h-2/5 md:w-22.5 md:h-22.5 mt-1.875 mb-7 grid place-items-center',
  welcomeDiv: 'text-2xl font-medium mb-2',
  descDiv: 'text-gray-300 font-medium px-2 mb-5',
  nameDiv: 'text-lg leading-6 mb-2',
  inputRoot: 'p-2 mb-1',
};

export const Preview = ({
  config,
  event,
  profile,
  joinOnClick,
  videoTileProps,
  classes,
  onNameChange,
}) => {
  const { tw } = useHMSTheme();
  const localPeer = useHMSStore(selectLocalPeer);
  const hmsActions = useHMSActions();
  const roomState = useHMSStore(selectRoomState);
  const host = config.role === ROLES.HOST;

  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-preview',
      }),
    [tw, classes],
  );

  /** This is to show error message only when input it touched or button is clicked */
  const [showValidation, setShowValidation] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const [previewStarted, setPreviewStarted] = useState(false);

  const audioEnabled = useHMSStore(selectIsLocalAudioEnabled);
  const videoEnabled = useHMSStore(selectIsLocalVideoDisplayEnabled);
  const isAllowedToPublish = useHMSStore(selectIsAllowedToPublish);

  const [username, setUsername] = useState(config.userName || '');
  const [isAllowedToJoin, setIsAllowedToJoin] = useState(false);
  const inputRef = useRef(null);

  useEffect(() => {
    // Call preview only when roomState is in disconnected state
    if (roomState === HMSRoomState.Disconnected) {
      if (!previewStarted) {
        hmsActions.preview(config).catch(err => {
          console.error('preview room err', err, err?.description);
        });
        setPreviewStarted(true);
      }
    }
    if (isBrowser) {
      window.onunload = () => hmsActions.leave();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hmsActions, roomState]);

  useEffect(() => {
    const value = config?.userName;
    value && setUsername(value);
  }, [config, config?.userName]);

  const inputProps = {
    compact: true,
    onChange: (e) => {
      setUsername(e.target.value);
      onNameChange?.(e.target.value);
      setShowValidation(true);
    },
    value: username,
    validation:
      showValidation && (!username || !username.replace(/\u200b/g, ' ').trim())
        ? 'Please enter name'
        : '',
    required: true,
  };

  useEffect(() => {
    if (!event || !event.start) {
      setIsAllowedToJoin(false);
      return;
    }

    if (event.started) {
      setIsAllowedToJoin(true);
      return;
    }

    const start = date(event.start).subtract(event.timeDiff);

    const isTimeToJoin = () => {
      const started = start ? now().isSameOrAfter(start) : false;
      const hostAllowed = start ? now().add(5, 'minutes').isSameOrAfter(start) : false;
      return started || hostAllowed;
    };

    if (isTimeToJoin()) {
      setIsAllowedToJoin(true);
    }
    else {
      let timer = setInterval(() => {
        if (isTimeToJoin()) {
          setIsAllowedToJoin(true);
          clearInterval(timer);
          timer = null;
        }
      }, 1000);

      return () => timer && clearInterval(timer);
    }
  }, [event]);

  const doJoin = useCallback(async () => {
    if (host) {
      await joinOnClick({
        audioMuted: !audioEnabled,
        videoMuted: !videoEnabled,
        name: username,
      });
    }
    else {
      try {
        await hmsActions.setLocalAudioEnabled(false);
      } catch (err) {
        console.error("Cannot toggle audio", err);
      }

      await joinOnClick({
        audioMuted: true,
        videoMuted: !videoEnabled,
        name: username,
      });
    }
  }, [joinOnClick, hmsActions, host, audioEnabled, videoEnabled, username]);
  
  const previewConnected = roomState === HMSRoomState.Preview || roomState === HMSRoomState.Connected;
  const disableJoin = inProgress || !previewConnected || !isAllowedToJoin;

  return (
    <Fragment>
      {/* root */}
      <div className={styler('root')}>
        <div className={styler('containerRoot')}>
          {/* header */}
          <div className={styler('header')}>
            {/* videoTile */}
            {localPeer ? (
              <VideoTile
                {...videoTileProps}
                peer={{ ...localPeer, name: username }}
                customAvatar={
                  <Avatar
                    image={profile?.ProfileImageUrl}
                    label={username}
                    icon={<PersonIcon style={{ width:'108px', height:'108px', margin: 'auto' }} />}
                   />
                }
                hmsVideoTrackId={localPeer.videoTrack}
                objectFit="cover"
                aspectRatio={{
                  width: 1,
                  height: 1,
                }}
                classes={classes?.videoTile}
                controlsComponent={
                  <PreviewControls
                    isAudioMuted={!audioEnabled}
                    isVideoMuted={!videoEnabled}
                    isAudioAllowed={isAllowedToPublish.audio}
                    isVideoAllowed={isAllowedToPublish.video}
                  />
                }
              />
            ) : (
              <ProgressIcon width="100" height="100" />
            )}
          </div>
          {/* welcomeDiv */}
          <div className={styler('welcomeDiv')}>Welcome to <span>{trimEnding(event?.name, ' event')}</span> event</div>
          {/* descDiv */}
          {event?.description && (<div className={styler('descDiv')}>{truncate(event?.description, 300)}</div>)}
          {/* nameDiv */}
          <div className={styler('nameDiv')}>What's your name?</div>
          {/* inputFieldRoot */}
          <div className={styler('inputRoot')}>
            <Input
              ref={inputRef}
              {...inputProps}
              autoCorrect="off"
              autoComplete="name"
            />
          </div>
          {/*Warning for safari audio output change*/}
          {isMobileDevice() && isSafari() && (
            <div className="mb-3">
              <DotIcon
                className="fill-current text-yellow-400 inline mr-2"
                width="8"
                height="8"
              />
              <Text size="sm" classes={{ root: 'inline' }}>
                Warning: The audio output can be redirected to earpiece
              </Text>
            </div>
          )}
          {/* joinButton */}
          <Button
            variant="emphasized"
            size="lg"
            iconRight={disableJoin}
            classes={classes?.joinButton}
            icon={inProgress ? <ProgressIcon /> : undefined}
            disabled={disableJoin}
            onClick={async () => {
              if (disableJoin) {
                return;
              }
              if (!username || !username.replace(/\u200b/g, ' ').trim()) {
                inputRef.current && inputRef.current.focus();
                setShowValidation(true);
                return;
              }
              setInProgress(true);
              doJoin().finally(() => {
                setInProgress(false);
              });
            }}
          >
            Join
          </Button>
        </div>
      </div>
    </Fragment>
  );
};