import React, { useMemo } from 'react';
import './index.css';
import { hmsUiClassParserGenerator } from '../../../common/utils';
import { useHMSActions, useHMSTheme, Button, Settings, MicOffIcon, MicOnIcon, CamOnIcon, CamOffIcon } from '@100mslive/hms-video-react';

const defaultClasses = {
  root:
    'flex absolute bottom-0 w-full p-3 bottom-background z-40 rounded-lg min-h-0 focus:outline-none',
  controls:
    'dark flex flex-1 self-center justify-center hover-hide space-x-1 relative',
  rightControls: 'dark flex items-center justify-self-end',
};

const Controls = ({
  isAudioMuted = false,
  isVideoMuted = false,
  buttonDisplay = 'rectangle',
  classes,
  isAudioAllowed = true,
  isVideoAllowed = true,
}) => {
  const { tw } = useHMSTheme();
  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-preview-controls',
      }),
    [tw, classes],
  );
  const hmsActions = useHMSActions();

  return (
    <div className={`${styler('root')}`}>
      <div className={`${styler('controls')}`}>
        {isAudioAllowed && (
          <Button
            iconOnly
            variant="no-fill"
            active={isAudioMuted}
            shape={buttonDisplay}
            onClick={async () => {
              try {
                await hmsActions.setLocalAudioEnabled(isAudioMuted);
              } catch (err) {
                console.error("Cannot toggle audio", err);
              }
            }}
          >
            {isAudioMuted ? <MicOffIcon /> : <MicOnIcon />}
          </Button>
        )}
        {isVideoAllowed && (
          <Button
            iconOnly
            variant="no-fill"
            active={isVideoMuted}
            shape={buttonDisplay}
            onClick={async () => {
              try {
                await hmsActions.setLocalVideoEnabled(isVideoMuted);
              } catch (err) {
                console.error("Cannot toggle video", err);
              }
            }}
          >
            {isVideoMuted ? <CamOffIcon /> : <CamOnIcon />}
          </Button>
        )}
      </div>
      <div className={`${styler('rightControls')}`}>
        <Settings key={0} previewMode={true} />
      </div>
    </div>
  );
};

export const PreviewControls = React.memo(Controls);