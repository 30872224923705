import React, { useMemo, useState, useContext } from 'react';
import { useHMSTheme, Text, Button } from '@100mslive/hms-video-react';
import { hmsUiClassParserGenerator } from '../../../common/utils';

import { FeedbackDisplay } from './FeedbackDisplay';
import { AppContext } from '../../../store/AppContext';
import { DEVICE } from '../../../common/constants';

const defaultClasses = {
  root: `h-full text-white flex items-center justify-center`,
  rootBg: `w-37.5 h-42.5 rounded-2xl`,
  rootOverlay: `relative rounded-2xl  flex flex-col text-center items-center space-y-6 pt-28 pb-6`,
  divider: `bg-gray-600  h-px w-96 my-8`,
  buttonWrapper: `space-x-5`,
  feedbackSection: `mt-48`,
  cancelFeedback: `justify-center pt-5 mr-48`,
};

export const PostLeaveDisplay = ({
  classes,
  joinRoomOnClick,
  goToBrokerloopOnClick,
  submitFeedbackOnClick,
  src,
}) => {
  const { tw } = useHMSTheme();
  const [showModal, setShowModal] = useState(false);
  const styler = useMemo(
    () =>
      hmsUiClassParserGenerator({
        tw,
        classes,
        defaultClasses,
        tag: 'hmsui-post-leave-display',
      }),
    [tw, classes],
  );

  const context = useContext(AppContext);
  const { loginInfo } = context;

  const deviceFromStorage = localStorage.getItem(DEVICE);
  const device = deviceFromStorage ? deviceFromStorage.trim().toLowerCase() : 'unknown';
  const showBackToBrokerloop = device !== 'android'; // due to Bubble's quirk, which doesn't allow deeplinks to the native app (not sure why) and opens the in-browser version (unusable on mobiles)

  return (
    <div className={styler('root')}>
      <div
        className={styler('rootBg')}
        style={{
          backgroundColor: '#333333',
          backgroundImage: src ? `url(${src})` : 'none',
        }}
      >
        <div
          className={styler('rootOverlay')}
          style={{
            background: `linear-gradient(180deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%)`,
          }}
        >
          <Text tag="h1" variant="heading">
            You left the room
          </Text>
          <Text tag="h1" variant="heading">
            Have a nice day {loginInfo.username} !
          </Text>
          <div className={styler('divider')}></div>
          <div className={styler('buttonWrapper')}>
            <Button variant="emphasized" onClick={joinRoomOnClick}>
              Join Again
            </Button>
            {showBackToBrokerloop && (
              <Button onClick={() => goToBrokerloopOnClick(loginInfo.event)}>Go to Brokerloop</Button>
            )}
          </div>
          <div
            className={styler('feedbackSection')}
            style={{
              marginTop: `35%`,
            }}
          >
            <Button
              style={{
                backgroundColor: `#E3E3E3`,
                color: `#767676`,
              }}
              onClick={() => setShowModal(true)}
            >
              Send Feedback
            </Button>
            <FeedbackDisplay
              username={loginInfo.username}
              showModal={showModal}
              setShowModal={setShowModal}
              submitOnClick={submitFeedbackOnClick}
              footer={
                <div className={styler('cancelFeedback')}>
                  <Button
                    onClick={() => setShowModal(false)}
                    variant="no-fill"
                    size="sm"
                    style={{ color: '#6085BC' }}
                  >
                    Cancel
                  </Button>
                </div>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};
