import { getBackendEndpoint, extractUrlCode } from './backendService';

export async function sendFeedback(
  backendEndpoint,
  userId,
  comment,
  choices,
  roomId,
  authToken
) {
  const headers = {
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  const response = await fetch(`${backendEndpoint}feedback`, {
    method: "POST",
    headers,
    body: JSON.stringify({
      code: roomId,
      user_id: userId,
      comment,
      choices
    })
  });

  const body = await response.json();

  if (body?.Success === false) {
    throw body;
  }

  return body;
}

export async function sendUserFeedback(userId, comment, choices, authToken) {
  const endpoint = getBackendEndpoint();
  const code = extractUrlCode();

  try {
    const response = await sendFeedback(endpoint, userId, comment, choices, code, authToken);
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export { getBackendEndpoint };
