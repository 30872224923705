import { Avatar as HmsAvatar, PersonIcon } from '@100mslive/hms-video-react';

export const Avatar = ({
  image,
  label,
  icon,
  ...props
}) => {
  return (
    // <Avatar
    //   label={username}
    //   avatarType={profile?.ProfileImageUrl ? 'image' : username ? 'initial' : 'icon'}
    //   image={profile?.ProfileImageUrl}
    //   icon={<PersonIcon style={{ width:'108px', height:'108px', margin: 'auto' }} />}
    // />

    <HmsAvatar
      label={label}
      avatarType={image ? 'image' : label ? 'initial' : 'icon'}
      image={image}
      // icon={icon || <PersonIcon style={{ width:'108px', height:'108px', margin: 'auto' }} />}
      icon={icon || <PersonIcon style={{ margin: 'auto' }} />}
      {...props}
    />
  );
};
