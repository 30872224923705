import { fetchWithRetry, getBackendEndpoint, extractUrlCode } from './backendService';

export async function getToken(
  tokenEndpoint,
  userId,
  roomId,
  authToken,
  bot
) {
  const headers = {
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`;
  }

  const retryPolicy = bot ? { retries: 1000000 } : {};

  const body = await fetchWithRetry(`${tokenEndpoint}get-token`, {
    method: "POST",
    headers,
    json: true,
    body: JSON.stringify({
      code: roomId,
      user_id: userId,
      bot
    })
  }, retryPolicy);

  if (body?.Success === false) {
    throw body;
  }

  return body;
}

export async function getUserToken(name, authToken) {
  const endpoint = getBackendEndpoint();
  const code = extractUrlCode();

  try {
    const response = await getToken(endpoint, name, code, authToken);
    return response;
  } catch (e) {
    console.log(e);
    return null;
  }
}

export { getBackendEndpoint };
