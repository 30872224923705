import React, {
  useState,
  Fragment,
  useEffect,
  useCallback,
} from "react";
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  HamburgerMenuIcon,
  Settings,
  SettingsIcon,
  useHMSStore,
  useHMSActions,
  selectLocalPeer,
  selectPermissions,
  FullScreenIcon,
  MessageModal,
  Text,
  RecordIcon,
  selectRecordingState,
  selectRTMPState,
  StarIcon,
} from "@100mslive/hms-video-react";
import urlJoin from 'url-join';
import { ROLES } from "../../common/roles";
import { hmsToast } from "./Notifications/hms-toast";
import { setFullScreenEnabled, joinQueryParams } from "../../common/utils";
import screenfull from "screenfull";
import { RecordingAndRTMPForm } from "./RecordingAndRTMPForm";
import { MuteAll } from "./MuteAll";
import { BEAM_BOT } from '../../common/constants';

const beamBotMeetingUrl = joinQueryParams(window.location.href, `${BEAM_BOT}=true`);

export const MoreSettings = ({
  event = {}
}) => {
  const permissions = useHMSStore(selectPermissions);
  const localPeer = useHMSStore(selectLocalPeer);
  const hmsActions = useHMSActions();

  const [showMenu, setShowMenu] = useState(false);
  const [showMuteAll, setShowMuteAll] = useState(false);
  const [showSettings, setShowSettings] = useState(false);
  const [showRecordingAndRTMPModal, setShowRecordingAndRTMPModal] = useState(false);
    
  const [rtmpURL, setRtmpURL] = useState("");
  const [streamKey, setStreamKey] = useState("");
  const recording = useHMSStore(selectRecordingState);
  const rtmp = useHMSStore(selectRTMPState);
  const [isRecordingOn, setIsRecordingOn] = useState(false);

  const [isFullScreenEnabled, setIsFullScreenEnabled] = useState(screenfull.isFullscreen);

  const amIHost = localPeer?.roleName.toLowerCase() === ROLES.HOST;

  useEffect(() => {
    if (screenfull.isEnabled) {
      screenfull.on("change", () => {
        setIsFullScreenEnabled(screenfull.isFullscreen);
      });
    }
  }, []);

  const getText = useCallback(() => {
    let text = "";
    if (rtmp.running) {
      text += "Streaming";
    }
    if (recording.browser.running) {
      if (text) text += "/";
      text += "Recording";
    }
    text += " is running";
    return text;
  }, [recording.browser.running, rtmp.running]);

  const startStopRTMPRecording = async (action, retry = false) => {
    try {
      if (action === "start" || action === "start_recording") {
        const meetingURL = beamBotMeetingUrl;
        const rtmpURLs = rtmpURL.length > 0 ? streamKey.length > 0 ? [urlJoin(rtmpURL, '/' + streamKey)] : [rtmpURL] : [];
        const record = isRecordingOn || action === "start_recording";

        await hmsActions.startRTMPOrRecording({
          meetingURL,
          rtmpURLs: rtmpURLs.length ? rtmpURLs : undefined,
          record,
        });
      } else if (action === "stop") {
        await hmsActions.stopRTMPAndRecording();
      } else {
        throw new Error(`invalid action: ${action}`);
      }
    } catch (error) {
      if (error?.description === "RTMP stream already running" && !retry) {
        await hmsActions.stopRTMPAndRecording();
        await startStopRTMPRecording(action, true);
      }
      else {
        console.error("failed to start/stop rtmp/recording", error);
        hmsToast(error.message);
      }
    } finally {
      setRtmpURL("");
      setStreamKey("");
      setIsRecordingOn(false);
      setShowRecordingAndRTMPModal(false);
    }
  };

  return (
    <Fragment>
      <ContextMenu
        menuOpen={showMenu}
        onTrigger={value => {
          setShowMenu(value);
        }}
        classes={{
          root: "static",
          trigger: "bg-transparent-0",
          menu: "mt-0 py-0 w-52",
        }}
        trigger={
          <Button
            iconOnly
            variant="no-fill"
            iconSize="md"
            shape="rectangle"
            active={showMenu}
            key="hamburgerIcon"
          >
            <HamburgerMenuIcon />
          </Button>
        }
        menuProps={{
          anchorOrigin: {
            vertical: "top",
            horizontal: "center",
          },
          transformOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        }}
      >
        {amIHost && event.options?.livestreaming && event.options?.custom?.livestream && (
          <ContextMenuItem
            icon={<RecordIcon />}
            label="Streaming/Recording"
            key="streaming-recording"
            onClick={() => {
              setShowRecordingAndRTMPModal(true);
            }}
          />
        )}
        {screenfull.isEnabled && (
          <ContextMenuItem
            icon={<FullScreenIcon />}
            label={`${isFullScreenEnabled ? "Exit " : ""}Full Screen`}
            key="toggleFullScreen"
            onClick={() => {
              setFullScreenEnabled(!isFullScreenEnabled);
            }}
          />
        )}
        {permissions.mute && (
          <ContextMenuItem
            label="Mute All"
            icon={<StarIcon />}
            onClick={() => {
              setShowMuteAll(true);
            }}
          />
        )}
        <ContextMenuItem
          icon={<SettingsIcon />}
          label="Device Settings"
          key="settings"
          onClick={() => {
            setShowSettings(true);
          }}
        />
      </ContextMenu>
      <Settings
        className="hidden"
        showModal={showSettings}
        onModalClose={() => setShowSettings(false)}
      />
      <MuteAll
        showModal={showMuteAll}
        onCloseModal={() => setShowMuteAll(false)}
      />
      <MessageModal
        title="Streaming/Recording"
        body={
          <RecordingAndRTMPForm
            RTMPURLs={rtmpURL}
            streamKeys={streamKey}
            isRecordingOn={isRecordingOn}
            recordingStatus={recording.browser.running}
            rtmpStatus={rtmp.running}
            setIsRecordingOn={setIsRecordingOn}
            setRTMPURLs={setRtmpURL}
            setStreamKeys={setStreamKey}
          />
        }
        footer={
          <>
            {(recording.browser.running || rtmp.running) && (
              <Text
                variant="body"
                size="md"
                classes={{ root: "mx-2 self-center text-yellow-500" }}
              >
                {getText()}
              </Text>
            )}
            <div className="space-x-1">
              <Button
                variant="danger"
                shape="rectangle"
                onClick={() => startStopRTMPRecording("stop")}
                disabled={!recording.browser.running && !rtmp.running}
              >
                Stop
              </Button>
              <Button
                variant="emphasized"
                shape="rectangle"
                onClick={() => startStopRTMPRecording("start")}
                disabled={recording.browser.running || rtmp.running}
              >
                Start
              </Button>
            </div>
          </>
        }
        show={showRecordingAndRTMPModal}
        onClose={() => setShowRecordingAndRTMPModal(false)}
      />
    </Fragment>
  );
};
