import React, { useEffect, useState } from 'react';
import { MessageModal, isMobileDevice, isSafari, Button, Text } from '@100mslive/hms-video-react';
import { DEVICE, OS } from '../../../common/constants';
import permissionsBlockedChrome from "../../../images/permissions-blocked-chrome.svg";
import permissionsBlockedSafari from "../../../images/permissions-blocked-safari.svg";
import settingsIcon from "../../../images/chrome-address-bar-settings-icon.svg";

export function PermissionErrorModal({ notification }) {
  const [deviceType, setDeviceType] = useState('');
  const [isSystemError, setIsSystemError] = useState(false);

  const os = localStorage.getItem(OS);
  const deviceFromStorage = localStorage.getItem(DEVICE);
  const device = deviceFromStorage ? deviceFromStorage.trim().toLowerCase() : 'unknown';
  const isAndroid = device === 'android';
  const isIOS = device === 'ios';
  const isMobile = isAndroid || isIOS || isMobileDevice();
  const isMacSafari = os === 'macos' && isSafari();

  useEffect(() => {
    if (
      !notification ||
      (notification.data?.code !== 3001 && notification.data?.code !== 3011) ||
      (notification.data?.code === 3001 && notification.data?.message.includes('screen'))
    ) {
      return;
    }
    console.error(`[${notification.type}]`, notification);
    const errorMessage = notification.data?.message;
    const hasAudio = errorMessage.includes('audio');
    const hasVideo = errorMessage.includes('video');
    const hasScreen = errorMessage.includes('screen');
    if (hasAudio && hasVideo) {
      setDeviceType('camera and microphone');
    } else if (hasAudio) {
      setDeviceType('microphone');
    } else if (hasVideo) {
      setDeviceType('camera');
    } else if (hasScreen) {
      setDeviceType('screen');
    }
    setIsSystemError(notification.data.code === 3011);
  }, [notification]);

  return deviceType ? (
    <MessageModal
      show={!!deviceType}
      onClose={() => setDeviceType('')}
      classes={{
        boxRoot: 'w-full m-auto',
        body: isMobile ? 'pb-2' : 'px-4 pb-4',
        title: isMobile ? 'text-lg' : '',
      }}
      title={isMobile ? `Site is blocked from using your ${deviceType}` : null}
      body={
        <div className="flex flex-row">
          {!isMobile && isMacSafari && (
            <img
              src={permissionsBlockedSafari}
              alt="permissions_error"
              className="mr-4 md:mr-8 object-contain flex justify-center w-60 md:w-96"
            />
          )}
          {!isMobile && !isMacSafari && (
            <img
              src={permissionsBlockedChrome}
              alt="permissions_error"
              className="mr-4 md:mr-8 object-contain flex justify-center w-60 md:w-96"
            />
          )}
          <div className="grow text-left">
            {!isMobile && (
              <Text tag="h2" variant="heading" size="md" classes={{ root: 'mb-6 -mr-6', rootHeadingMd: 'font-bold' }}>
                Site is blocked from using your {deviceType}
              </Text>
            )}

            <div className="text-lg md:text-base">
              {/* IOS prompt text */}
              {isMobile && isIOS
                ? 'Enable permissions by reloading this page and clicking "Allow" on the pop-up, or change settings from the address bar.'
                : null}

              {/* Prompt for android devices */}
              {isMobile && isAndroid
                ? `To allow other users to see and hear you, click the blocked camera icon in your browser's address bar.`
                : null}

              {/* Prompt for desktops */}
              {/* {!isMobile ? `Access to ${deviceType} is required. ` : null} */}

              {isSystemError && !isMobile
                ? `Enable permissions for ${deviceType}${deviceType === 'screen' ? 'share' : ''} from system settings.`
                : null}

              {/* {!isSystemError && !isMobile
                ? `Enable permissions for ${deviceType}${
                    deviceType === 'screen' ? 'share' : ''
                  } from address bar or browser settings.`
                : null} */}

              {!isSystemError && !isMobile && isMacSafari && (
                <ol className="list-decimal list-inside space-y-3">
                  <li>{`Click the "Safari" menu and open "Settings for ${window.location.hostname}".`}</li>
                  <li>Allow {deviceType}.</li>
                </ol>
              )}

              {!isSystemError && !isMobile && !isMacSafari && (
                <ol className="list-decimal list-inside space-y-3">
                  <li>Click the <img src={settingsIcon} alt="settings_icon" className="inline-block object-contain" /> page info icon in your browser's address bar.</li>
                  <li>Turn on {deviceType}.</li>
                </ol>
              )}
            </div>
          </div>
        </div>
      }
      footer={isMobile && (isIOS || isAndroid) && (
        <div className="flex space-x-1">
          {/* CTA section */}
          {isMobile && isIOS ? (
            <>
              <Button onClick={() => window.location.reload()}>
                Reload
              </Button>
              <Button variant="standard" onClick={() => setDeviceType('')}>
                Continue anyway
              </Button>
            </>
          ) : null}

          {isMobile && isAndroid ? (
            <>
              <Button onClick={() => setDeviceType('')}>
                I've allowed access
              </Button>
              <Button variant="standard" onClick={() => setDeviceType('')}>
                Continue anyway
              </Button>
            </>
          ) : null}
        </div>
      )}
    />
  ) : null;
}
