import React, { useContext, useState, useEffect } from "react";
import { useSearchParam } from "react-use";
import { useHistory, useParams } from "react-router-dom";
import {
  Button,
  MessageModal,
  ProgressIcon,
  Text,
  // Preview,
} from "@100mslive/hms-video-react";
import { v4 as uuid } from "uuid";
import { AppContext } from "../store/AppContext";
import { getToken } from "../services/tokenService";
import { convertLoginInfoToJoinConfig } from "../store/appContextUtils";
import { Preview } from '../views/previewView';
import { Notifications } from "../views/components/Notifications/Notifications";
import { USERNAME_KEY, BEAM_BOT } from "../common/constants";
import { likeUuid, date, now, isToday, toQueryString, joinQueryParams } from '../common/utils';
import { WaitingScreenDisplay } from '../views/components/WaitingScreenDisplay/WaitingScreenDisplay';
import { ROLES } from '../common/roles';

const PreviewScreen = ({ getUserToken }) => {
  const history = useHistory();
  const context = useContext(AppContext);
  const { loginInfo, setLoginInfo, setMaxTileCount, tokenEndpoint } = context;
  const { roomId: urlRoomId, authToken } = useParams();
  const [token, setToken] = useState(null);
  const [isWaiting, setIsWaiting] = useState(false);
  const [start, setStart] = useState({});
  const [error, setError] = useState({
    title: "",
    body: "",
    fatal: false,
  });
  const beamBot = useSearchParam(BEAM_BOT) === "true";

  const usernameFromStorage = localStorage.getItem(USERNAME_KEY);

  const username = loginInfo.profile?.username
    || likeUuid(loginInfo.username || usernameFromStorage) ? '' : loginInfo.username || usernameFromStorage;

  const calcStart = (event) => {
    const start = event.start ? date(event.start).subtract(event.timeDiff) : null;
    const today = isToday(start);
    const startDate = start && start.format("MMM D"); // Jan 1
    const startTime = start && start.format("LT"); // 1:00 PM
    setStart({ date: startDate, time: startTime, isToday: today });
  };
  
  const timeDifferenceWith = serverTime => {
    const precise  = true;
    const timeDiff = serverTime ? date(serverTime).diff(now(), 'milliseconds', precise) : 0;
    return timeDiff;
  }

  const redirectTo = (url, params = {}) => {
    const preserve = {};

    if (beamBot) {
      preserve[BEAM_BOT] = beamBot;
    }

    if (Object.keys(preserve).length) {
      params = { ...preserve, ...params };
    }

    const queryParams = toQueryString(params);
    const targetUrl   = joinQueryParams(url, queryParams);

    history.push(targetUrl);
  };

  useEffect(() => {
    if (beamBot) {
      join({ audioMuted: true, videoMuted: true, name: "beam", bot: true });
      return;
    }

    getToken(tokenEndpoint, uuid(), urlRoomId, authToken)
      .then(response => {
        const { waiting, event, token, role, username, profile } = response;

        const timeDiff = timeDifferenceWith(event?.servertime);
        event.timeDiff = timeDiff;

        calcStart(event);
        setIsWaiting(waiting);
        setToken(token);
        setLoginInfo({
          role,
          username,
          profile,
          event,
          timeDiff,
        });
      })
      .catch(error => {
        let message = error?.ErrorText;

        if (!message || message === 'error') {
          message = "An error occurred while connecting to server, please try again later.";
        }

        console.error("Token API Error", message, error);

        setError({
          title: "Unable to join room",
          body: message,
          fatal: true,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    loginInfo.env,
    tokenEndpoint,
    urlRoomId,
    getUserToken,
    authToken,
    beamBot,
  ]);

  const join = ({ audioMuted, videoMuted, name, bot }) => {
    getToken(tokenEndpoint, name, urlRoomId, authToken, bot)
      .then(response => {
        const { token, role, profile, event } = response;

        setLoginInfo({
          token,
          audioMuted,
          videoMuted,
          role: bot ? ROLES.BOT : role,
          roomId: urlRoomId,
          username: name,
          profile,
          event,
        });

        if (authToken) redirectTo(`/meeting/${urlRoomId}/${authToken}`);
        else redirectTo(`/meeting/${urlRoomId}`);
      })
      .catch(error => {
        let message = error?.ErrorText;

        if (!message || message === 'error') {
          message = "An error occurred while connecting to server, please try again later.";
        }

        console.error("Token API Error", message, error);

        setError({
          title: "Unable to join room",
          body: message,
          fatal: false,
        });
      });
  };

  const onChange = ({
    selectedVideoInput,
    selectedAudioInput,
    selectedAudioOutput,
    maxTileCount,
  }) => {
    setLoginInfo({
      selectedVideoInput,
      selectedAudioInput,
      selectedAudioOutput,
    });
    setMaxTileCount(maxTileCount);
  };

  const goBack = () => {
    window.location.reload();
  };

  const reloadRoom = () => {
    window.location.reload();
  };

  const leaveRoom = () => {
    redirectTo(`/leave/${urlRoomId}/${authToken}`);
  };

  const clearError = () => {
    setError({ title: "", body: "", fatal: false });
  };

  if (error.title && error.fatal) {
    return (
      <MessageModal
        title={error.title}
        body={error.body}
        onClose={leaveRoom}
        footer={(
          <>
            <Button classes={{ root: 'mr-2' }} onClick={reloadRoom}>Reload</Button>

            {token && (
              <Button onClick={leaveRoom}>Leave</Button>
            )}
          </>
        )}
      />
    );
  }

  return (
    <div className="h-full">
      <div className="flex justify-center h-full items-center">
        {token ? (
          <Preview
            joinOnClick={join}
            goBackOnClick={goBack}
            messageOnClose={goBack}
            onChange={onChange}
            config={convertLoginInfoToJoinConfig({
              token,
              roomId:   urlRoomId,
              role:     loginInfo.role,
              env:      loginInfo.env,
              username: username
            })}
            event={loginInfo.event}
            profile={loginInfo.profile}
          />
        ) : isWaiting ? (
          <WaitingScreenDisplay
            event={loginInfo.event}
            username={likeUuid(loginInfo.username || usernameFromStorage) ? '' : loginInfo.username || usernameFromStorage}
            message={(
              loginInfo.role !== ROLES.HOST ? (
                <Text tag="h2" variant="heading" size="md">
                  <div>Waiting for the host to start the meeting</div>

                  {start && (
                    <div>{start.isToday ? `scheduled at ${start.time}` : `scheduled at ${start.time} on ${start.date}`}</div>
                  )}
                </Text>
              ) : start ? (
                <Text tag="h2" variant="heading" size="md">
                  <div>Event not started yet, {start.isToday ? `scheduled at ${start.time}` : `scheduled at ${start.time} on ${start.date}`}</div>
                  <div>Meeting room opens 15 minutes in advance, please wait.</div>
                </Text>
              ) : (
                <Text tag="h2" variant="heading" size="md">
                  <div>Event not started yet, please wait.</div>
                </Text>
              )
            )}
            footer={(
              <Button onClick={reloadRoom}>Reload</Button>
            )}
          />
        ) : (
          <ProgressIcon width="100" height="100" />
        )}
        {error.title && (
          <MessageModal
            title={error.title}
            body={error.body}
            onClose={clearError}
            footer={<Button onClick={clearError}>Dismiss</Button>}
          />
        )}
        <Notifications />
      </div>
    </div>
  );
};

export default PreviewScreen;
